import { deepOApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { PortalMappingDTO } from '@/api/types/deepo/portal-mappings.ts'

export interface DeepOPortalQueryParams {
  deepbox_node_id: string
  box_node_id: string
}

export default class DeepOPortalMappingsAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    companyId: string,
    params: DeepOPortalQueryParams,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<PortalMappingDTO>> {
    return this.httpClient.get<PortalMappingDTO>(
      `/portal/v1/mappings/boxes/${params.deepbox_node_id}/${params.box_node_id}`,
      undefined,
      {
        ...options,
        headers: { 'company-id': companyId },
      },
    )
  }
}

export const deepOPortalMappingsAPIService = new DeepOPortalMappingsAPIService(
  deepOApi,
)
