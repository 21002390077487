export function isSentryEnabled() {
  return import.meta.env.VITE_SENTRY_ENABLED === 'true'
}

export function isLocalEnv() {
  return import.meta.env.VITE_ENVIRONMENT === 'local'
}

export function isDevEnv() {
  return import.meta.env.VITE_ENVIRONMENT === 'dev'
}

export function isIntEnv() {
  return import.meta.env.VITE_ENVIRONMENT === 'int'
}

export function isProdEnv() {
  return import.meta.env.VITE_ENVIRONMENT === 'prod'
}

export function isLocalOrDevEnv() {
  return isLocalEnv() || isDevEnv()
}
