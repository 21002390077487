import type { Node } from '@/api/types/deepbox/node.ts'
import {
  isNodeDeepVFile,
  isNodeFinancialStatement,
} from '@/utils/helpers/nodes.ts'
import type { PathSegment } from '@/api/types/deepbox/path.ts'
import type { SearchItem } from '@/api/types/deepbox/search.ts'

export function getDeepVNodeUrl(nodeId: string) {
  const baseUrl = import.meta.env.VITE_DEEPV_BASE_URL
  if (!baseUrl || !nodeId) return null
  return `${baseUrl}doc/box/${nodeId}`
}

export function getFinancialStatementNodeUrl(nodeId: string) {
  const baseUrl = import.meta.env.VITE_FINANCIAL_STATEMENT_FRONTEND_URL
  if (!baseUrl || !nodeId) return null
  return `${baseUrl}?nodeId=${nodeId}`
}

export function canFinancialStatement(node: Node) {
  const baseUrl = import.meta.env.VITE_FINANCIAL_STATEMENT_FRONTEND_URL
  return !!baseUrl && isNodeFinancialStatement(node)
}

export function isExternalNodeLink(node: Node) {
  return isNodeDeepVFile(node) || canFinancialStatement(node)
}

export function getExternalNodeLink(node: Node) {
  if (isNodeDeepVFile(node)) {
    return getDeepVNodeUrl(node.nodeId)
  } else if (isNodeFinancialStatement(node)) {
    return getFinancialStatementNodeUrl(node.nodeId)
  }

  return null
}

export function checkNodesPolicy(
  nodes: Node[] | PathSegment[] | SearchItem[],
  policyName: string,
) {
  if (!nodes || !policyName) return false
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].policy[policyName] === false) {
      return false
    }
  }
  return true
}

export function checkNodesType(nodes: Node[] | PathSegment[], type: string) {
  if (!nodes || !type) return false
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].type !== type) {
      return false
    }
  }
  return true
}
