import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { AdminDeepBoxData } from '@/api/types/deepbox/admin'

export default class DeepBoxAdminDeepBoxesAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  post(params: Record<string, unknown>): Promise<HttpClientResponse<never>> {
    return this.httpClient.post<never>('admin/deepBoxes', params)
  }

  get(
    params: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<AdminDeepBoxData>> {
    return this.httpClient.get<AdminDeepBoxData>(
      'admin/deepBoxes',
      params,
      options,
    )
  }
}

export const deepBoxAdminDeepBoxesAPI = new DeepBoxAdminDeepBoxesAPIService(
  deepBoxApi,
)
