import type { App } from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export default {
  install(app: App) {
    const componentFiles = import.meta.glob('./components/fields/*.vue', {
      eager: true,
    })

    Object.entries(componentFiles).forEach(([path, m]) => {
      if (!path) return
      const componentName = upperFirst(
        camelCase(
          path
            .split('/')
            .pop()
            .replace(/\.\w+$/, ''),
        ),
      )

      app.component(`${componentName}`, m.default)
    })
  },
}
