<template>
  <v-menu
    v-if="canUserSeeTasks && !mobile"
    v-model="menu"
    :close-on-content-click="false"
    location="bottom right"
    max-width="350"
  >
    <template #activator="{ props: ActivatorProps }">
      <v-btn
        v-bind="ActivatorProps"
        class="pa-0"
        :loading="isFetchingTasksInitial"
      >
        <v-badge
          :color="tasks.length > 0 ? 'warning' : 'primary'"
          :content="`${tasks.length}`"
          inline
        >
          <v-icon :color="doActiveApiPolling ? undefined : 'warning'" start>
            {{ doActiveApiPolling ? 'far fa-inbox-in' : 'far fa-inbox' }}
          </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <DeepFlowTasksList :tasks="tasks" @click:item="onTaskClick" />
    <DeepFlowIFrameDialog
      v-if="currentTask && dialogDeepFlowIFrame"
      v-model="dialogDeepFlowIFrame"
      closable
      :iframe-src="taskIFrameUrl"
    />
  </v-menu>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from 'vue'
import DeepFlowTasksList from '@/components/deepflow/DeepFlowTasksList.vue'
import { deepOPengTasksAPIService } from '@/api/deepo/peng-task'
import type { Task } from '@/api/types/deepo/peng-task'
import { toast } from 'vue-sonner'
import { useDeepBoxAdminStore } from '@/stores/deepbox/admin/admin'
import DeepFlowIFrameDialog from '@/components/deepflow/DeepFlowIFrameDialog.vue'
import { useLocalStorage } from '@vueuse/core'
import { useDisplay } from 'vuetify'
import { isLocalOrDevEnv } from '@/utils/helpers/env.ts'

const props = defineProps({
  orgId: {
    type: String,
    default: undefined,
  },
  typeId: {
    type: String,
    default: undefined,
  },
  boxId: {
    type: String,
    default: undefined,
  },
})

const menu = ref(false)
const tasks = ref<Task[]>([])

const isFetchingTasksInitial = ref(true)

async function fetchUserTasks() {
  try {
    const { data } = await deepOPengTasksAPIService.getByCurrentUser({
      companyId: props.orgId,
      deepBoxNodeId: props.typeId,
      boxNodeId: props.boxId,
    })
    tasks.value = data.tasks
  } catch (e) {
    console.error(e)
  } finally {
    isFetchingTasksInitial.value = false
  }
}

const deepBoxAdminStore = useDeepBoxAdminStore()
const canAdminAccess = computed(
  () => deepBoxAdminStore.adminBox?.boxPolicy?.canAdminAccess || false,
)

const { xs, mobile } = useDisplay()
const canUserSeeTasks = computed(
  () => !xs.value && isLocalOrDevEnv() && canAdminAccess,
)

function clearCurrentInterval() {
  clearInterval(currentPollingId.value)
}

const hasAllNeededParamsCallTheAPI = computed(
  () => props.orgId && props.typeId && props.boxId,
)

const currentPollingId = ref(0)
const doActiveApiPolling = useLocalStorage('activeDeepFlowTaskPolling', true)

function startActivePolling() {
  currentPollingId.value = setInterval(() => {
    fetchUserTasks()
  }, 5000)
}

watch(
  () => hasAllNeededParamsCallTheAPI.value,
  (newValue) => {
    if (newValue) {
      if (canUserSeeTasks.value) {
        fetchUserTasks()
        if (doActiveApiPolling.value) {
          startActivePolling()
        }
      } else {
        clearCurrentInterval()
      }
    }
  },
  { immediate: true },
)

watch(
  () => doActiveApiPolling.value,
  (newValue) => {
    if (newValue) {
      if (canUserSeeTasks.value) {
        fetchUserTasks()
        if (doActiveApiPolling.value) {
          clearCurrentInterval()
          startActivePolling()
        }
      } else {
        clearCurrentInterval()
      }
      toast.success('Active DeepFlow API polling enabled')
    } else {
      clearCurrentInterval()
      toast.warning('Active DeepFlow API polling disabled')
    }
  },
)

onUnmounted(() => clearCurrentInterval())

const currentTask = ref<Task | undefined>(undefined)
const dialogDeepFlowIFrame = ref(false)

function onTaskClick({ task }) {
  currentTask.value = task
  dialogDeepFlowIFrame.value = true
}

const taskIFrameUrl = computed(() => {
  if (!currentTask.value) return
  return `${import.meta.env.VITE_DEEPFLOW_FRONTEND_BASE_URL}runtime/deepbox/${
    currentTask.value?.definitionId
  }/-1?orgId=${currentTask.value?.companyId}&typeId=${
    currentTask.value?.deepBoxNodeId
  }&boxId=${currentTask.value?.boxNodeId}& &instanceId=${
    currentTask.value?.instanceId
  }&environment=DeepBoxInteractive`
})
</script>
