<template>
  <div>
    <slot v-if="name" name="initials">
      <BoxInitials v-bind="$attrs" :name="name" :size="initialsSize" />
    </slot>
    <v-avatar v-else v-bind="$attrs" :size="boxSize" tile>
      <slot name="content">
        <slot name="icon">
          <v-img cover min-width="100%" src="/svg/box-32-px.svg" />
        </slot>
      </slot>
    </v-avatar>
  </div>
</template>

<script lang="ts" setup>
import BoxInitials from '@/components/box/BoxInitials.vue'

defineProps({
  name: {
    type: String,
    default: null,
  },
  boxSize: {
    type: [String, Number],
    default: 28,
  },
  initialsSize: {
    type: String,
    default: 'md',
  },
  iconSize: {
    type: String,
    default: '100%',
  },
})
</script>
