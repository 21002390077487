import { deepAdminApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { UserMeLanguage } from '@/api/types/deepadmin/users/user-me-language'

export default class DeepAdminUsersMeLanguageAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<UserMeLanguage>> {
    return this.httpClient.get<UserMeLanguage>(
      'users/me/language/',
      params,
      options,
    )
  }

  post(
    body?: Record<string, unknown>,
  ): Promise<HttpClientResponse<UserMeLanguage>> {
    return this.httpClient.post<UserMeLanguage>('users/me/language/', body)
  }
}

export const deepAdminUsersMeLanguageAPI =
  new DeepAdminUsersMeLanguageAPIService(deepAdminApi)
