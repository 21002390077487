//COMPANIES STRUCTURES
const COMPANY_STRUCTURES = {
  COMPANY_STRUCTURE_NO_BOX: 'nobox',
  COMPANY_STRUCTURE_PERSONAL: 'personal',
  COMPANY_STRUCTURE_SOLE_PROPRIETORSHIP: 'soleproprietorship',
  COMPANY_STRUCTURE_ASSOCIATION: 'association',
  COMPANY_STRUCTURE_UNREGISTERED: 'unregistered',
  COMPANY_STRUCTURE_FAMILY: 'family',
}

const COMPANY_PERSONAL_STRUCTURE_TYPES = [
  COMPANY_STRUCTURES.COMPANY_STRUCTURE_NO_BOX,
  COMPANY_STRUCTURES.COMPANY_STRUCTURE_PERSONAL,
  COMPANY_STRUCTURES.COMPANY_STRUCTURE_UNREGISTERED,
  COMPANY_STRUCTURES.COMPANY_STRUCTURE_FAMILY,
]

const ONBOARDING_COMPANY_VERIFICATION_STATUS = {
  ONBOARDING_COMPANY_VERIFICATION_STATUS_NONE: 'none',
  ONBOARDING_COMPANY_VERIFICATION_STATUS_MANUAL_OVERRIDE: 'manual_override',
  ONBOARDING_COMPANY_VERIFICATION_STATUS_VERIFIED: 'verified',
  ONBOARDING_COMPANY_VERIFICATION_STATUS_INPROGRESS: 'inprogress',
  ONBOARDING_COMPANY_VERIFICATION_STATUS_VALIDATION_ERROR: 'validation_error',
}

const VERIFICATION_STATES = {
  VERIFICATION_STATE_NONE: 'none',
  VERIFICATION_STATE_WEAK: 'weak',
  VERIFICATION_STATE_STRONG: 'strong',
}

const VERIFICATION_PROCESS_TYPES = {
  VERIFICATION_PROCESS_TYPE_STARTED: 'started',
  VERIFICATION_PROCESS_TYPE_IN_PROGRESS: 'in_progress',
  VERIFICATION_PROCESS_TYPE_ERROR: 'error',
}

const VERIFIED_VERIFICATION_STATES = [
  VERIFICATION_STATES.VERIFICATION_STATE_WEAK,
  VERIFICATION_STATES.VERIFICATION_STATE_STRONG,
]

const MIME_TYPES_VIDEO = {
  MIME_TYPE_VIDEO_AVI: 'video/avi',
  MIME_TYPE_VIDEO_MP4: 'video/mp4',
  MIME_TYPE_VIDEO_MPEG: 'video/mpeg',
  MIME_TYPE_VIDEO_OGG: 'audio/ogg', // can also be displayed as video
  MIME_TYPE_VIDEO_QUICKTIME: 'video/quicktime',
  MIME_TYPE_VIDEO_WEBM: 'video/webm',
  MIME_TYPE_VIDEO_X_MATROSKA: 'video/x-matroska',
}

const MIME_TYPES_AUDIO = {
  MIME_TYPE_AUDIO_OGG: 'audio/ogg',
  MIME_TYPE_AUDIO_MPEG: 'audio/mpeg',
  MIME_TYPE_AUDIO_WAV: 'audio/wav',
}

const MIME_TYPES = {
  MIME_TYPE_FILES: 'application/vnd.deepbox-system.files',
  MIME_TYPE_INBOX: 'application/vnd.deepbox-system.queue',
  MIME_TYPE_TRASH: 'application/vnd.deepbox-system.trash',
  MIME_TYPE_FOLDER: 'application/vnd.deepbox.folder',
  MIME_TYPE_GENERIC: 'application/vnd.deepbox.generic',
  MIME_TYPE_DEEPV: 'application/vnd.deepv.container',
  MIME_TYPE_JSON: 'application/json',
  MIME_TYPE_PDF: 'application/pdf',
  MIME_TYPE_TXT: 'text/plain',
  MIME_TYPE_PNG: 'image/png',
  MIME_TYPE_JPEG: 'image/jpeg',
  MIME_TYPE_JPG: 'image/jpg',
  MIME_TYPE_WEBP: 'image/webp',
  MIME_TYPE_GIF: 'image/gif',
  MIME_TYPE_DOC: 'application/msword',
  MIME_TYPE_SVG: 'image/svg+xml',
  MIME_TYPE_DOCX:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MIME_TYPE_XLS: 'application/vnd.ms-excel',
  MIME_TYPE_XLSX:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  MIME_TYPE_PPT: 'application/vnd.ms-powerpoint',
  MIME_TYPE_PPTX:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  MIME_TYPE_NUMBERS: 'application/vnd.apple.numbers',
  MIME_TYPE_NUMBERS_OLD: 'application/x-iwork-numbers-sffnumbers',
  MIME_TYPE_PAGES: 'application/vnd.apple.pages',
  MIME_TYPE_PAGES_OLD: 'application/x-iwork-pages-sffpages',
  MIME_TYPE_KEYNOTE: 'application/vnd.apple.keynote',
  MIME_TYPE_KEYNOTE_OLD: 'application/x-iwork-keynote-sffkey',
  MIME_TYPE_STREAM: 'application/octet-stream',
  MIME_TYPE_MARKDOWN: 'text/x-web-markdown',
  MIME_TYPE_DEEPPAY_PAYMENT_ORDER:
    'application/vnd.abacus.deeppay.payment-order',
  ...MIME_TYPES_VIDEO,
  ...MIME_TYPES_AUDIO,
}

const FILE_PREVIEW_SUPPORTED_MIME_TYPES = [
  MIME_TYPES.MIME_TYPE_PDF,
  MIME_TYPES.MIME_TYPE_PNG,
  MIME_TYPES.MIME_TYPE_JPEG,
  MIME_TYPES.MIME_TYPE_GIF,
  MIME_TYPES.MIME_TYPE_WEBP,
  MIME_TYPES.MIME_TYPE_SVG,
  MIME_TYPES.MIME_TYPE_DEEPPAY_PAYMENT_ORDER,
  MIME_TYPES.MIME_TYPE_JSON,
  MIME_TYPES.MIME_TYPE_TXT,
  // video
  MIME_TYPES.MIME_TYPE_VIDEO_MP4,
  MIME_TYPES.MIME_TYPE_VIDEO_WEBM,
  // audio
  MIME_TYPES.MIME_TYPE_AUDIO_MPEG,
  MIME_TYPES.MIME_TYPE_AUDIO_WAV,
]

const FILE_EDIT_SUPPORTED_MIME_TYPES = [
  MIME_TYPES.MIME_TYPE_DOC,
  MIME_TYPES.MIME_TYPE_DOCX,
  MIME_TYPES.MIME_TYPE_XLS,
  MIME_TYPES.MIME_TYPE_XLSX,
  MIME_TYPES.MIME_TYPE_PPT,
  MIME_TYPES.MIME_TYPE_PPTX,
  // TXT is not supported because the content is not updated from OO on DeepBox
  // MIME_TYPES.MIME_TYPE_TXT
]

const FILE_EXTENSION = {
  aac: 'aac', //Windows audio file
  adt: 'adt',
  adts: 'adts',
  accdb: 'accdb', //Microsoft Access database file
  accde: 'accde', //Microsoft Access execute-only file
  accdr: 'accdr', //Microsoft Access runtime database
  accdt: 'accdt', //Microsoft Access database template
  aif: 'aif', //Audio Interchange File format file
  aifc: 'aifc',
  aiff: 'aiff',
  aspx: 'aspx', //ASP.NET Active Server page
  avi: 'avi', //Audio Video Interleave movie or sound file
  bat: 'bat', //PC batch file
  bin: 'bin', //Binary compressed file
  bmp: 'bmp', //Bitmap file
  cab: 'cab', //Windows Cabinet file
  cda: 'cda', //CD Audio Track
  csv: 'csv', //Comma-separated values file
  deepv: 'deepv', // DeepCloud DeepV files
  dif: 'dif', //Spreadsheet data interchange format file
  dll: 'dll', //Dynamic Link Library file
  doc: 'doc', //Microsoft Word document before Word 2007
  docm: 'doc', //Microsoft Word macro-enabled document
  docx: 'docx', //Microsoft Word document
  dot: 'dot', //Microsoft Word template before Word 2007
  dotm: 'dotm',
  dotx: 'dotx', //Microsoft Word template
  eml: 'eml', //Email file created by Outlook Express, Windows Live Mail, and other programs
  eps: 'eps', //Encapsulated Postscript file
  exe: 'exe', //Executable program file
  flv: 'flv', //Flash-compatible video file
  gif: 'gif', //Graphical Interchange Format file
  htm: 'htm', //Hypertext markup language page
  html: 'html',
  ini: 'ini', //Windows initialization configuration file
  iso: 'iso', //ISO-9660 disc image
  jar: 'jar', //Java architecture file
  jpg: 'jpg', //Joint Photographic Experts Group photo file
  jpeg: 'jpeg',
  key: 'key', //Apple Keynote File Format
  m4a: 'm4a', //MPEG-4 audio file
  mdb: 'mdb', //Microsoft Access database before Access 2007
  mid: 'mid', //Musical Instrument Digital Interface file
  midi: 'midi',
  mov: 'mov', //Apple QuickTime movie file
  mp3: 'mp3', //MPEG layer 3 audio file
  mp4: 'mp4', //MPEG 4 video
  mpeg: 'mpeg', //Moving Picture Experts Group movie file
  mpg: 'mpg', //MPEG 1 system stream
  msi: 'msi', //Microsoft installer file
  mui: 'mui', //Multilingual User Interface file
  numbers: 'numbers', //Apple Numbers File Format
  pages: 'pages', //Apple Pages File Format
  pdf: 'pdf', //Portable Document Format file
  png: 'png', //Portable Network Graphics file
  pot: 'pot', //Microsoft PowerPoint template before PowerPoint 2007
  potm: 'potm', //Microsoft PowerPoint macro-enabled template
  potx: 'potx', //Microsoft PowerPoint template
  ppa: 'ppa',
  ppam: 'ppam', //Microsoft PowerPoint add-in
  pps: 'pps', //Microsoft PowerPoint slideshow before PowerPoint 2007
  ppsm: 'ppsm', //Microsoft PowerPoint macro-enabled slideshow
  ppsx: 'ppsx', //Microsoft PowerPoint slideshow
  ppt: 'ppt', //Microsoft PowerPoint format before PowerPoint 2007
  pptm: 'pptm', //Microsoft PowerPoint macro-enabled presentation
  pptx: 'pptx', //Microsoft PowerPoint presentation
  psd: 'psd', //Adobe Photoshop file
  pst: 'pst', //Outlook data store
  pub: 'pub', //Microsoft Publisher file
  rar: 'rar', //Roshal Archive compressed file
  rtf: 'rtf', //Rich Text Format file
  sldm: 'sldm', //Microsoft PowerPoint macro-enabled slide
  sldx: 'sldx', //Microsoft PowerPoint slide
  swf: 'swf', //Shockwave Flash file
  sys: 'sys', //Microsoft DOS and Windows system settings and variables file
  tif: 'tif', //Tagged Image Format file
  tiff: 'tiff',
  tmp: 'tmp', //Temporary data file
  txt: 'txt', //Unformatted text file
  vob: 'vob', //Video object file
  vsd: 'vsd', //Microsoft Visio drawing before Visio 2013
  vsdm: 'vsdm', //Microsoft Visio macro-enabled drawing
  vsdx: 'vsdx', //Microsoft Visio drawing file
  vss: 'vss', //Microsoft Visio stencil before Visio 2013
  vssm: 'vssm', //Microsoft Visio macro-enabled stencil
  vst: 'vst', //Microsoft Visio template before Visio 2013
  vstm: 'vstm', //Microsoft Visio macro-enabled template
  vstx: 'vstx', //Microsoft Visio template
  wav: 'wav', //Wave audio file
  wbk: 'wbk', //Microsoft Word backup document
  wks: 'wks', //Microsoft Works file
  webp: 'webp', //Google File Format for replacement for JPEG, PNG, and GIF file formats.
  wma: 'wma', //Windows Media Audio file
  wmd: 'wmd', //Windows Media Download file
  wmv: 'wmv', //Windows Media Video file
  wmz: 'wmz', //Windows Media skins file
  wms: 'wms',
  wpd: 'wpd', //WordPerfect document
  wp5: 'wp5',
  xla: 'xla', //Microsoft Excel add-in or macro file
  xlam: 'xlam', //Microsoft Excel add-in after Excel 2007
  xll: 'xll', //Microsoft Excel DLL-based add-in
  xlm: 'xlm', //Microsoft Excel macro before Excel 2007
  xls: 'xls', //Microsoft Excel workbook before Excel 2007
  xlsm: 'xlsm', //Microsoft Excel macro-enabled workbook after Excel 2007
  xlsb: 'xlsb',
  xlsx: 'xlsx', //Microsoft Excel workbook after Excel 2007
  xlt: 'xlt', //Microsoft Excel template before Excel 2007
  xltm: 'xltm', //Microsoft Excel macro-enabled template after Excel 2007
  xltx: 'xltx', //Microsoft Excel template after Excel 2007
  xps: 'xps', //XML-based document
  zip: 'zip', //Compressed file
}

const FILE_EXTENSION_IMAGES = [
  FILE_EXTENSION.png,
  FILE_EXTENSION.jpg,
  FILE_EXTENSION.jpeg,
  FILE_EXTENSION.gif,
  FILE_EXTENSION.webp,
]

const FILE_EXTENSION_MIME_TYPE = {
  [FILE_EXTENSION.doc]: MIME_TYPES.MIME_TYPE_DOC,
  [FILE_EXTENSION.docx]: MIME_TYPES.MIME_TYPE_DOCX,
  [FILE_EXTENSION.xls]: MIME_TYPES.MIME_TYPE_XLS,
  [FILE_EXTENSION.xlsx]: MIME_TYPES.MIME_TYPE_XLSX,
  [FILE_EXTENSION.ppt]: MIME_TYPES.MIME_TYPE_PPT,
  [FILE_EXTENSION.pptx]: MIME_TYPES.MIME_TYPE_PPTX,
  [FILE_EXTENSION.pdf]: MIME_TYPES.MIME_TYPE_PDF,
  [FILE_EXTENSION.txt]: MIME_TYPES.MIME_TYPE_TXT,
}

const MIME_TYPE_FILE_EXTENSION = {
  [MIME_TYPES.MIME_TYPE_DOC]: FILE_EXTENSION.doc,
  [MIME_TYPES.MIME_TYPE_DOCX]: FILE_EXTENSION.docx,
  [MIME_TYPES.MIME_TYPE_XLS]: FILE_EXTENSION.xls,
  [MIME_TYPES.MIME_TYPE_XLSX]: FILE_EXTENSION.xlsx,
  [MIME_TYPES.MIME_TYPE_PPT]: MIME_TYPES.MIME_TYPE_PPT,
  [MIME_TYPES.MIME_TYPE_PPTX]: FILE_EXTENSION.pptx,
  [MIME_TYPES.MIME_TYPE_PDF]: FILE_EXTENSION.pdf,
}

const AV_SCAN_ERROR_LEVELS = {
  AV_SCAN_ERROR_LEVEL_INFO: 'info',
  AV_SCAN_ERROR_LEVEL_WARN: 'warn',
  AV_SCAN_ERROR_LEVEL_ERROR: 'error',
}

const AV_SCAN_ERRORS = {
  AV_SCAN_VIRUS_FOUND: 'avScan.virusFound',
  AV_SCAN_FILE_TO_BIG: 'avScan.fileTooBig',
}

const DOWNLOAD_API_STATUS = {
  DOWNLOAD_API_STATUS_IN_PROGRESS: 'in-progress',
  DOWNLOAD_API_STATUS_READY_WITH_ISSUES: 'ready-with-issues',
  DOWNLOAD_API_STATUS_READY: 'ready',
}

const DEEP_SIGN_SIGN_STATUSES = {
  DEEP_SIGN_SIGN_STATUS_PENDING: 'in-progress',
  DEEP_SIGN_SIGN_STATUS_IN_PROGRESS: 'in-progress',
  DEEP_SIGN_SIGN_STATUS_SIGNED: 'signed',
  DEEP_SIGN_SIGN_STATUS_REJECTED: 'rejected',
}

const DEEP_SIGN_DOCUMENT_STATUSES = {
  DEEP_SIGN_DOCUMENT_STATUS_DRAFT: 'draft',
  DEEP_SIGN_DOCUMENT_STATUS_IN_PROGRESS: 'in-progress',
  DEEP_SIGN_DOCUMENT_STATUS_SIGNED: 'signed',
  DEEP_SIGN_DOCUMENT_STATUS_WITHDRAWN: 'withdrawn',
  DEEP_SIGN_DOCUMENT_STATUS_REJECTED: 'rejected',
}

const DEEP_SIGN_SIGNATURE_MODES = {
  DEEP_SIGN_SIGNATURE_MODE_TIMESTAMP: 'timestamp', // SES
  DEEP_SIGN_SIGNATURE_MODE_ADVANCED: 'advanced', // AES
  DEEP_SIGN_SIGNATURE_MODE_QUALIFIED: 'qualified', //QES
}

//DEEP_SIGN JURISDICTION TYPES
const DEEP_SIGN_JURISDICTION_TYPES = {
  DEEP_SIGN_JURISDICTION_TYPE_ZERTES: 'zertes',
  DEEP_SIGN_JURISDICTION_TYPE_EIDAS: 'eidas',
}

// DEEPSIGN AUTHORITY SERVICES
const DEEP_SIGN_AUTHORITY_SERVICES = {
  DEEP_SIGN_AUTHORITY_SERVICE_DID: 'did', // DeepID
  DEEP_SIGN_AUTHORITY_SERVICE_RAS: 'ras', // Swisscom Mobile ID
  DEEP_SIGN_AUTHORITY_SERVICE_YAPID: 'yapid', // YapealID (Just for debugging purposes)
}

const USER_BETA_FEATURES = {
  USER_BETA_FEATURE_DEEPSIGN: 'deepsign',
  USER_BETA_FEATURE_ONLYOFFICE: 'onlyoffice',
  USER_BETA_FEATURE_DEEP_ID: 'deepid',
  USER_BETA_FEATURE_HYBRID_ADMIN: 'hybrid-admin',
}

const ORG_BETA_FEATURES = {}

const ANALYSIS_STATUS = {
  ANALYSIS_STATUS_RUNNING: 'running', // analysis called, waiting for completion
  ANALYSIS_STATUS_ANALYZED: 'analyzed', // analysis completed (success)
  ANALYSIS_STATUS_FAILED: 'failed', // analysis completed (failed)
  ANALYSIS_STATUS_ERROR: 'error', // internal error calling analysis service
  ANALYSIS_STATUS_APPROVED: 'approved', // analysis approved
}

const BOX_DETAILS_DRAWER_SCROLL_IDS = {
  BOX_DETAILS_DRAWER_SCROLL_ID_DETAILS: 'BOX_DETAILS_DRAWER_ID_DETAILS',
  BOX_DETAILS_DRAWER_SCROLL_ID_TAGS: 'BOX_DETAILS_DRAWER_ID_TAGS',
  BOX_DETAILS_DRAWER_SCROLL_ID_COMMENTS: 'BOX_DETAILS_DRAWER_ID_COMMENTS',
}

const ONBOARDING_QUERY_TYPES = {
  ONBOARDING_QUERY_TYPE_DEEPBOX: 'deepbox',
  ONBOARDING_QUERY_TYPE_DEEPV: 'deepv',
  ONBOARDING_QUERY_TYPE_DEEPSIGN: 'deepsign',
}

const ONBOARDING_SERVICES = {
  ONBOARDING_SERVICE_DEEPV: 'deepv',
  ONBOARDING_SERVICE_DEEPSIGN: 'deepsign',
}

const BOX_TYPES = {
  BOX_TYPE_HOOP: 'hoop',
  BOX_TYPE_DEEP_ABA_NINJA: 'deep-ninja',
  BOX_TYPE_DEEP_POC_DEEPID_IDENTITY: 'poc-deepid-identity',
}

const BOX_VARIANTS = {
  BOX_VARIANT_ADVANCED: 'advanced',
  BOX_VARIANT_ADVANCED_PER_USER: 'advanced-per-use',
}

const USER_ROLES = {
  USER_ROLE_MANAGE_BOXES: 'MANAGE_BOXES',
  USER_ROLE_MANAGE_COMPANY: 'MANAGE_COMPANY',
  USER_ROLE_MANAGE_MEMBERS: 'MANAGE_MEMBERS',
  USER_ROLE_MANAGE_SUB_ACCESS: 'MANAGE_SUB_ACCESS',
  USER_ROLE_ORDER_SUB: 'ORDER_SUB',
  USER_ROLE_VIEW_COMPANY: 'VIEW_COMPANY',
  USER_ROLE_VIEW_SUB: 'VIEW_SUB',
  USER_ROLE_VIEW_TOKEN: 'VIEW_TOKEN',
}

const ORGANIZATION_ROLES = {
  ORGANIZATION_ROLE_OWNER: 'owner',
  ORGANIZATION_ROLE_MEMBER: 'member',
}

const STORE_SERVICES = {
  STORE_SERVICE_HOOP: 'hoop',
  STORE_SERVICE_ABA_NINJA: 'aba_ninja',
}

const DEEPSIGN_USER_TYPES = {
  INITIATOR: 'initiator',
  SIGNEE: 'signee',
  OBSERVER: 'observer',
}

const NODE_VIEW_MODE_IDS = {
  LIST: 'LIST',
  LIST_DENSE: 'LIST_DENSE',
}

const SUPPORTED_FEATURE_TAGS = {
  DEADLINE: 'deadline',
}

export const constants = {
  DEFAULT_APP_TITLE: 'DeepBox',
  FILETREE_QUEUE: 'queue',
  FILETREE_FILES: 'files',
  FILETREE_TRASH: 'trash',
  FILETREE_DEADLINES: 'deadlines',
  FILETREE_INBOX: 'inbox',
  FILETREE_SHARES: 'shares',
  // BOXES TO SHOW
  BOXES_TO_SHOW: 6,
  // HTTP_RESPONSE
  HTTP_RESPONSE_SUCCESS: 200,
  ITEMS_PER_PAGE: 50,
  // KEYCODES
  KEY_CMD: 91,
  KEY_CTRL: 17,
  KEY_SHIFT: 16,
  // MIME TYPES
  ...MIME_TYPES,
  FILE_PREVIEW_SUPPORTED_MIME_TYPES,
  ORDER_DEFAULT_INBOX: 'modified.time desc', // modified.time will be converted into modifiedTime for api requests
  ORDER_DEFAULT_TRASH: 'deletedTime desc',
  ORDER_DEFAULT_FILES: 'displayName asc',
  // NODE TYPE
  NODE_TYPE_FILE: 'file',
  NODE_TYPE_FOLDER: 'folder',
  // ROUTES
  ROUTE_FILES: 'organization-types-type-boxes-box-files',
  ROUTE_FILES_NODE: 'organization-types-type-boxes-box-files-node',
  ROUTE_QUEUE: 'organization-types-type-boxes-box-inbox',
  ROUTE_TRASH: 'organization-types-type-boxes-box-trash',
  ROUTE_TRASH_NODE: 'organization-types-type-boxes-box-trash-node',
  ROUTE_SEARCH: 'organization-types-type-boxes-box-search',
  // DEEPBOX SECTIONS
  SECTION_INBOX: 'inbox',
  SECTION_QUEUE: 'queue',
  SECTION_FILES: 'files',
  SECTION_FILES_NODE: 'fileNodes',
  SECTION_TRASH: 'trash',
  SECTION_TRASH_NODE: 'trashNodes',
  SNACKBAR_MAIN_COLOR: '#212529',
  // ONBOARDING_COMPANY_VERIFICATION_STATUS
  ...ONBOARDING_COMPANY_VERIFICATION_STATUS,
  // DEEP_SIGN
  ...DEEP_SIGN_SIGN_STATUSES,
  ...DEEP_SIGN_DOCUMENT_STATUSES,
  ...DEEP_SIGN_SIGNATURE_MODES,
  ...DEEP_SIGN_AUTHORITY_SERVICES,
  // decimal value 40MB
  SIGN_MAX_SIZE: 40000000,
  // UPLOAD MAX SIZE
  UPLOAD_MAX_SIZE: 2000000000,
  // COMPANIES STRUCTURES
  ...COMPANY_STRUCTURES,
  COMPANY_PERSONAL_STRUCTURE_TYPES,
  // AV_SCAN
  ...AV_SCAN_ERROR_LEVELS,
  ...AV_SCAN_ERRORS,
  // AV_SCAN
  ...DOWNLOAD_API_STATUS,
  FILE_EXTENSION,
  FILE_EXTENSION_IMAGES,
  FILE_EXTENSION_MIME_TYPE,
  MIME_TYPE_FILE_EXTENSION,
  FILE_EDIT_SUPPORTED_MIME_TYPES,
  ...USER_BETA_FEATURES,
  ...ORG_BETA_FEATURES,
  ...ANALYSIS_STATUS,
  ...BOX_DETAILS_DRAWER_SCROLL_IDS,
  ...ONBOARDING_QUERY_TYPES,
  ...BOX_TYPES,
  ...BOX_VARIANTS,
  ...DEEP_SIGN_JURISDICTION_TYPES,
  ...ONBOARDING_SERVICES,
  ...VERIFICATION_STATES,
  ...VERIFICATION_PROCESS_TYPES,
  VERIFIED_VERIFICATION_STATES,
  ...USER_ROLES,
  ...ORGANIZATION_ROLES,
  // STORE
  STORE_SERVICES,
  ...STORE_SERVICES,
  DEEPSIGN_USER_TYPES,
  NODE_VIEW_MODE_IDS,
  SUPPORTED_FEATURE_TAGS,
}
