<template>
  <JsonTreeView
    v-if="settingsDevStore.devModeEnabled && settingsDevStore.devDebugEnabled"
    v-bind="$attrs"
  />
</template>

<script lang="ts" setup>
// stores
import { useSettingsDevStore } from '@/stores/settings/settings-dev'
import { JsonTreeView } from 'json-tree-view-vue3'

const settingsDevStore = useSettingsDevStore()
</script>
