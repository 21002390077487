<template>
  <!-- MOBILE APPBAR -->
  <DeepHeader v-if="isMobile" :env="currentEnv" :logo="logo">
    <template #prepend>
      <slot name="mobile-prepend"></slot>
    </template>

    <template #prepend-end>
      <slot name="mobile-content"></slot>
    </template>

    <template #append>
      <slot name="mobile-append"></slot>
      <div v-if="globalStore.mobileSearch"></div>
      <template v-else>
        <DeepAppSwitcher :env="currentEnv" />
        <AppBarUserAccountMenu />
      </template>
    </template>
  </DeepHeader>
  <!-- /MOBILE APPBAR -->
  <!-- DESKTOP APPBAR -->
  <DeepHeader
    v-else
    :env="currentEnv"
    :logo="logo"
    @click:logo="onClickShowDialogAppInfo"
  >
    <template #prepend-start>
      <slot name="desktop-prepend-start"></slot>
    </template>
    <template #prepend-end>
      <slot name="desktop-prepend-end"></slot>
    </template>

    <template v-if="$slots['desktop-center']" #center>
      <v-spacer />
      <slot class="v-toolbar__center" name="desktop-center"></slot>
      <v-spacer />
    </template>
    <template #append-start>
      <slot name="desktop-append"></slot>
    </template>
    <template #append>
      <div v-if="globalStore.mobileSearch"></div>
      <template v-else>
        <DeepAppSwitcher :env="currentEnv" />
        <AppBarUserAccountMenu />
      </template>
    </template>
  </DeepHeader>
  <!-- /DESKTOP APPBAR -->

  <DialogAppInfo v-model="showDialogAppInfo" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { DeepAppSwitcher, DeepHeader } from '@deepcloud/deep-ui-lib'
import AppBarUserAccountMenu from '@/components/appbar/AppBarUserAccountMenu.vue'
import DialogAppInfo from '@/components/dialogs/DialogAppInfo.vue'
import { useDialogAppInfo } from '@/composables/use-dialog-app-info.ts'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'
import { useGlobalStore } from '@/stores/global/global.ts'

const globalStore = useGlobalStore()

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
  hideLogo: {
    type: Boolean,
    default: false,
  },
})
const { showDialogAppInfo, onClickShowDialogAppInfo } = useDialogAppInfo()
const deepAdminUsersMeStore = useDeepAdminUsersMeStore()

const currentEnv = import.meta.env.VITE_ENVIRONMENT

const logo = computed(() => {
  if (props.hideLogo) {
    return undefined
  }

  return {
    appId: 'deepbox',
    to: {
      name: 'organization',
      params: {
        organization: deepAdminUsersMeStore.selectedOrganization?.group_id,
      },
    },
  }
})
</script>
