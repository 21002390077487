// types & constants
import { constants } from '@/constants'
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'

export function isPersonalOrganizationNoBox(org: UserMeOrganization) {
  if (!org) return null

  return (
    constants.COMPANY_PERSONAL_STRUCTURE_TYPES.includes(org?.structure) &&
    org?.verification_state === constants.VERIFICATION_STATE_NONE &&
    org?.verification_process_status === null
  )
}
