import { useNode } from '@/composables/use-node'
import type { Tag } from '@/api/types/deepbox/tag'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core.ts'
import cloneDeep from 'lodash/cloneDeep'

export function useNodeTags() {
  // stores
  const { getNodeById } = useNode()
  const deepBoxCoreStore = useDeepBoxCoreStore()

  // UPDATE NODE TAGS
  function updateNodeTags(payload: { nodeId: string; tags: Tag[] }) {
    const node = getNodeById(payload.nodeId)
    if (node) {
      node.tags = payload.tags
    }

    const lastSelectedNode = cloneDeep(deepBoxCoreStore.lastSelectedNode)
    if (lastSelectedNode && lastSelectedNode?.item?.nodeId === node.nodeId) {
      lastSelectedNode.item.tags = [...node.tags]
      deepBoxCoreStore.lastSelectedNode = lastSelectedNode
    }
  }

  return {
    updateNodeTags,
  }
}
