import { ref } from 'vue'
import { toast } from 'vue-sonner'
import { useI18n } from 'vue-i18n'

export function useCopyToClipboard() {
  const isCopyToClipboardLoading = ref(false)
  const { t } = useI18n()

  function copyToClipboard(
    value: string | number,
    msgSuccess?: string,
    msgError?: string,
  ) {
    return new Promise((resolve, reject) => {
      isCopyToClipboardLoading.value = true
      // implement timeout to see the loader
      setTimeout(() => {
        navigator.clipboard
          .writeText(value.toString())
          .then(() => {
            if (msgSuccess) {
              toast.success(msgSuccess)
            } else {
              toast.success(t('toast.success.copy_value'))
            }
            isCopyToClipboardLoading.value = false
            return resolve()
          })
          .catch((err) => {
            console.log(err)
            if (msgError) {
              toast.success(msgError)
            } else {
              toast.error(t('toast.success.copy_value'))
            }
            isCopyToClipboardLoading.value = false
            reject(err)
          })
      }, 500)
    })
  }

  return {
    // states
    isCopyToClipboardLoading,
    // actions
    copyToClipboard,
  }
}
