<template>
  <DeepDialog
    v-bind="$attrs"
    v-model="model"
    icon="far fa-info-circle"
    :max-width="300"
    title="DeepBox INFO"
  >
    <template #content>
      <v-row>
        <v-col cols="12">
          <v-list>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :title="item.title"
            >
              <template #subtitle>
                <v-btn
                  color="primary"
                  :loading="
                    copyToClipboard.isCopyToClipboardLoading.value &&
                    valueToCopyToClipboard === item.value
                  "
                  rounded
                  size="small"
                  variant="outlined"
                  @click="onCopyValueToClipboard(item.value)"
                >
                  <v-icon v-if="item.icon" start>{{ item.icon }}</v-icon>
                  {{ item.value }}
                </v-btn>
              </template>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </template>
  </DeepDialog>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { DeepDialog } from '@deepcloud/deep-ui-lib'
import { useCopyToClipboard } from '@/composables/use-copy-to-clipboard'
import { formatDateTimeByDefaultFormat } from '@/utils/helpers/date.ts'

const model = defineModel({ type: Boolean, default: false })

const copyToClipboard = useCopyToClipboard()

const items = computed(() => [
  {
    title: 'Environment',
    value: import.meta.env.VITE_ENVIRONMENT,
    icon: 'far fa-code',
  },
  {
    title: 'Release Tag',
    value: import.meta.env.VITE_RELEASE_TAG,
    icon: 'far fa-tag',
  },
  {
    title: 'Release Datetime',
    value: formatDateTimeByDefaultFormat(import.meta.env.VITE_RELEASE_DATETIME),
    icon: 'far fa-clock',
  },
  {
    title: 'Release Commit ID',
    value: import.meta.env.VITE_RELEASE_COMMIT_ID,
    icon: 'far fa-code-commit',
  },
])

const valueToCopyToClipboard = ref(undefined)

async function onCopyValueToClipboard(value) {
  valueToCopyToClipboard.value = value
  await copyToClipboard.copyToClipboard(value)
  valueToCopyToClipboard.value = undefined
}
</script>
