import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Tag } from '@/api/types/deepbox/tag'
import { useLocalStorage } from '@vueuse/core'

export const useDeepBoxDeepBoxesBoxesInboxStore = defineStore(
  'deepBoxDeepBoxesBoxesInboxStore',
  () => {
    // const filters = ref({});
    const filters = useLocalStorage('inbox_box_filters', {})

    function hasFilterByBoxId(boxId: string) {
      return Object.keys(filters.value).includes(boxId)
    }

    function hasFilterTagsByBoxId(boxId: string) {
      if (hasFilterByBoxId(boxId)) {
        return filters.value[boxId].tags.length > 0
      }
      return false
    }

    function getFilterByTagsStringByBoxId(boxId: string) {
      const tagKeys: string[] = []
      if (hasFilterByBoxId(boxId)) {
        // -------------------------------------------------------------------------------------------------------------
        // PLEASE READ!
        // This is a converter, which converts the values saved in the LS
        // form array with objects to array with strings (tag key)
        // this is need to support the "old" behaviour, which was changed with https://jira.abacus.ch/browse/DEEPBOX-5328
        const mostUsedTagsLSContainsObjects = filters.value[boxId].tags.some(
          (a) => typeof a === 'object',
        )
        if (mostUsedTagsLSContainsObjects) {
          filters.value[boxId].tags = filters.value[boxId].tags.map(
            (a) => a.key,
          )
        }
        // -------------------------------------------------------------------------------------------------------------

        filters.value[boxId].tags.forEach((tag: string) => {
          tagKeys.push(tag)
        })
        return tagKeys.join(',')
      }
      return undefined
    }

    function addFilterByKeyAndBoxId(
      boxId: string,
      key: string,
      value: unknown,
    ) {
      if (hasFilterByBoxId(boxId)) {
        filters.value[boxId][key] = value
      } else {
        filters.value = {
          ...filters.value,
          [boxId]: {
            [key]: value,
          },
        }
      }
    }

    function addFilterTagsByBoxId(boxId: string, tags: Tag[]) {
      addFilterByKeyAndBoxId(boxId, 'tags', tags)
    }

    return {
      // filters
      hasFilterByBoxId,
      filters,
      hasFilterTagsByBoxId,
      getFilterByTagsStringByBoxId,
      addFilterTagsByBoxId,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxDeepBoxesBoxesInboxStore, import.meta.hot),
  )
}
