import type { Node } from '@/api/types/deepbox/node'
import type { PathSegment } from '@/api/types/deepbox/path'
import type { SearchItem } from '@/api/types/deepbox/search'

export function useNodePolicy() {
  const canAddChildren = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canAddChildren || false

  const canAdminAccess = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canAdminAccess || false

  const canAnalyze = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canAnalyze || false

  const canComment = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canComment || false

  const canDelete = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canDelete || false

  const canDirectDownload = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canDirectDownload || false

  const canDownload = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canDownload || false

  const canListChildren = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canListChildren || false

  const canI18n = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canI18n || false

  const canMoveOutOfBox = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canMoveOutOfBox || false

  const canMoveWithinBox = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canMoveWithinBox || false

  const canPurge = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canPurge || false

  const canReadNodeInfo = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canReadNodeInfo || false

  const canRename = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canRename || false

  const canRevert = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canRevert || false

  const canRevision = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canRevision || false

  const canSign = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canSign || false

  const canTag = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canTag || false
  const canWatch = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canWatch || false
  const canMove = (node: Node | PathSegment | SearchItem) =>
    node?.policy?.canMoveOutOfBox || node?.policy?.canMoveWithinBox || false

  return {
    canAddChildren,
    canAdminAccess,
    canAnalyze,
    canComment,
    canDelete,
    canDirectDownload,
    canDownload,
    canI18n,
    canListChildren,
    canMoveOutOfBox,
    canMoveWithinBox,
    canPurge,
    canReadNodeInfo,
    canRename,
    canRevert,
    canRevision,
    canSign,
    canTag,
    canWatch,
    canMove,
  }
}
