import { ref, watch } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useTheme } from 'vuetify'

export const useSettingsStore = defineStore(
  'settings',
  () => {
    const navDrawerMini = ref(false)
    const currentTheme = ref('light')
    const theme = useTheme()

    watch(
      () => theme.current.value,
      (newValue) => {
        currentTheme.value = newValue.dark ? 'dark' : 'light'
      },
      { immediate: true },
    )

    return {
      // state
      navDrawerMini,
      currentTheme,
    }
  },
  { persist: true },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot))
}
