<template>
  <LayoutDefault>
    <ContainerCenteredFull class="text-center">
      <ContainerError :error="error" :organization="organization" />
    </ContainerCenteredFull>
  </LayoutDefault>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import * as Sentry from '@sentry/vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import ContainerError from '@/components/containers/ContainerError.vue'
import ContainerCenteredFull from '@/components/containers/ContainerCenteredFull.vue'
import { isSentryEnabled } from '@/utils/helpers/env.ts'

const props = defineProps({
  error: {
    type: [Object, Error],
    default: () => ({}),
  },
  organization: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

const isDeepBoxError = computed(() => props.error?.deepBoxError)

useHead(() => {
  let title = ''
  if (isDeepBoxError.value) {
    if (props.error?.message) {
      title = t(props.error.message) as string
    }
  } else if (!props.error?.message) {
    title =
      props.error?.statusCode === 404
        ? (t('error.page_not_found') as string)
        : (t('error.error_occurred') as string)
  } else {
    title = props.error.message
  }
  return {
    title,
  }
})

if (!isDeepBoxError.value) {
  let objError
  if (props.error?.data) {
    objError = new Error(props.error?.data)
  } else if (props.error?.message) {
    objError = new Error(props.error?.message)
  } else {
    objError = new Error(props.error)
  }
  if (isSentryEnabled()) {
    Sentry.captureException(objError)
  }
}
</script>
