import type { Node } from '@/api/types/deepbox/node'
import { constants } from '@/constants'
import type { PathSegment } from '@/api/types/deepbox/path.ts'
import type { SearchItem } from '@/api/types/deepbox/search.ts'
import { getFileExtension } from '@/utils/deep/'

// MIMETYPES check
export function isNodeMimeTypeDOC(node: Node | PathSegment | SearchItem) {
  return node.mimeType === constants.MIME_TYPE_DOC
}

export function isNodeMimeTypeDOCX(node: Node | PathSegment | SearchItem) {
  return node.mimeType === constants.MIME_TYPE_DOCX
}

export function isNodeMimeTypePPT(node: Node | PathSegment | SearchItem) {
  return node.mimeType === constants.MIME_TYPE_PPT
}

export function isNodeMimeTypePPTX(node: Node | PathSegment | SearchItem) {
  return node.mimeType === constants.MIME_TYPE_PPTX
}

export function isNodeMimeTypeXLS(node: Node | PathSegment | SearchItem) {
  return node.mimeType === constants.MIME_TYPE_XLS
}

export function isNodeMimeTypeXLSX(node: Node | PathSegment | SearchItem) {
  return node.mimeType === constants.MIME_TYPE_XLSX
}

export function isNodeMimeTypePAGES(node: Node | PathSegment | SearchItem) {
  return [constants.MIME_TYPE_PAGES, constants.MIME_TYPE_PAGES_OLD].includes(
    node.mimeType,
  )
}

export function isNodeMimeTypeNUMBERS(node: Node | PathSegment | SearchItem) {
  return [
    constants.MIME_TYPE_NUMBERS,
    constants.MIME_TYPE_NUMBERS_OLD,
  ].includes(node.mimeType)
}

export function isNodeMimeTypeKEYNOTE(node: Node | PathSegment | SearchItem) {
  return [
    constants.MIME_TYPE_KEYNOTE,
    constants.MIME_TYPE_KEYNOTE_OLD,
  ].includes(node.mimeType)
}

export function isNodeImage(node: Node | PathSegment | SearchItem) {
  return node?.mimeType?.indexOf('image') === 0
}

export function isNodePdf(node: Node | PathSegment | SearchItem) {
  return node?.mimeType === constants.MIME_TYPE_PDF
}

export function isNodeDeepVFile(node: Node | PathSegment | SearchItem) {
  return node?.mimeType === constants.MIME_TYPE_DEEPV
}

export function isNodeAudioFile(node: Node | PathSegment | SearchItem) {
  return node?.mimeType?.indexOf('audio') === 0
}

export function isNodeVideoFile(node: Node | PathSegment | SearchItem) {
  return node?.mimeType?.indexOf('video') === 0
}

export function isNodeMimeTypePNG(node: Node | PathSegment | SearchItem) {
  return node?.mimeType === constants.MIME_TYPE_PNG
}

export function isNodeMimeTypeJPEG(node: Node | PathSegment | SearchItem) {
  return node?.mimeType === constants.MIME_TYPE_JPEG
}

export function isNodeMimeTypeJPG(node: Node | PathSegment | SearchItem) {
  return node?.mimeType === constants.MIME_TYPE_JPG
}

export function isNodeDocument(node: Node | PathSegment | SearchItem) {
  return !isNodeAudioFile(node) && !isNodeVideoFile(node) && !isNodeImage(node)
}

export function isNodeTypeFile(node: Node | PathSegment | SearchItem) {
  return node?.type === 'file'
}

export function isNodeDeepPayPaymentOrder(
  node: Node | PathSegment | SearchItem,
) {
  return node?.mimeType === constants.MIME_TYPE_DEEPPAY_PAYMENT_ORDER
}

export function isNodeFinancialStatement(
  node: Node | PathSegment | SearchItem,
) {
  return node?.type === 'file' && getFileExtension(node.name) === 'fstmt'
}

export function getNodeIcon(node: Node | PathSegment | SearchItem) {
  /* UNDEFINED */
  if (node === undefined || node === null || (!node.type && !node.mimeType)) {
    return 'unknown'
  }

  /* FOLDER */
  if (node.type === 'folder') {
    return 'folder'
  }
  /* MEDIA */
  if (node.mimeType.indexOf('audio') === 0) {
    return 'audio'
  }
  if (node.mimeType.indexOf('video') === 0) {
    return 'video'
  }
  if (node.mimeType.indexOf('image') === 0) {
    return 'image'
  }
  /* OFFICE */
  if (isNodeMimeTypeDOC(node) || isNodeMimeTypeDOCX(node)) {
    return 'word'
  }
  if (isNodeMimeTypePPT(node) || isNodeMimeTypePPTX(node)) {
    return 'powerpoint'
  }
  if (isNodeMimeTypeXLS(node) || isNodeMimeTypeXLSX(node)) {
    return 'excel'
  }
  /* PDF */
  if (isNodePdf(node)) {
    return 'pdf'
  }
  /* APPLE */
  if (isNodeMimeTypePAGES(node)) {
    return 'pages'
  }
  if (isNodeMimeTypeNUMBERS(node)) {
    return 'numbers'
  }
  if (isNodeMimeTypeKEYNOTE(node)) {
    return 'keynote'
  }
  /* DEEPV */
  if (isNodeDeepVFile(node)) {
    return 'deepv'
  }
  /* DeepPay */
  if (isNodeDeepPayPaymentOrder(node)) {
    return 'deeppay'
  }
  /* Abacus Financial Statement */
  if (isNodeFinancialStatement(node)) {
    return 'financial-statement'
  }
  /* UNKNOWN */
  return 'unknown'
}
