import axios from 'axios'
import { setInterceptorHeaderAuthorization } from '@/api/interceptors/utils'
import { AxiosHttpClient } from '@/api/http-client'

// create axios
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_DEEP_OPEN_API_DEFINITION_API_BASE_URL, // api base_url
})

axiosInstance.interceptors.request.use(async (config) => {
  const headers = {
    'X-api-key': import.meta.env.VITE_DEEP_OPEN_API_DEFINITION_API_KEY,
  }

  await setInterceptorHeaderAuthorization(headers)

  Object.assign(config.headers, headers)
  return {
    ...config,
  }
})

export default new AxiosHttpClient(axiosInstance)
