import { deepSignApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'

export default class DeepSignDocumentsAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.get<never>('documents', params, options)
  }
}

export const deepSignDocumentsAPI = new DeepSignDocumentsAPIService(deepSignApi)
