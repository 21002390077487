import { computed, ref, watch } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { constants } from '@/constants'
import helpers from '@/utils/helpers'
import type { DeepBoxOverview } from '@/api/types/deepbox/deep-box'
import type {
  Overview,
  OverviewQueryParams,
} from '@/api/types/deepbox/overview'
import { deepBoxOverviewAPI } from '@/api/deepbox/overview/overview'
import { deepBoxOverviewDeepBoxesAPI } from '@/api/deepbox/overview/overview-deepboxes'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import { useDeepBoxDeepBoxesBoxesFavoritesStore } from '@/stores/deepbox/deepboxes/boxes/favorites'
import type { Box } from '@/api/types/deepbox/box.ts'

export const useDeepBoxOverviewStore = defineStore('deepBoxOverview', () => {
  const overview = ref<Overview>()
  const typeOverview = ref<DeepBoxOverview>()
  const fetchOverviewPending = ref(false)
  const fetchOverviewFullPending = ref(false)
  const fetchNextBoxesPending = ref(false)
  const fetchTypeOverviewPending = ref(false)

  const types = computed(() => {
    if (!overview.value) return []

    // Create a new array with sorted deepBoxes
    return helpers.typesSortAlphaNum(overview.value?.deepBoxes).map((type) => {
      // Return a new object with sorted boxes to avoid mutating the original reactive object
      return {
        ...type,
        boxes: helpers.boxSortAlphaNum(type.boxes),
      }
    })
  })

  const sharedBoxes = computed(() => {
    if (!overview.value) return []
    return helpers.boxSortAlphaNum(overview.value?.sharedWithMe.boxes)
  })

  const hasShareBoxes = computed(() => sharedBoxes.value.length > 0)

  const recents = computed(() => {
    return overview.value?.recents.boxes || []
  })

  const queues = computed(() => {
    return overview.value?.queues.boxes || []
  })

  const deadlines = computed(() => {
    return overview.value?.deadlines.boxes || []
  })

  const company = computed(() => {
    return overview.value?.company
  })

  const canOnboard = computed(() => {
    return overview.value?.canOnboard
  })

  const favorites = computed(() => {
    return overview.value?.favorites
  })

  watch(
    () => favorites.value,
    (newValue) => {
      if (newValue) {
        const deepBoxDeepBoxesBoxesFavoritesStore =
          useDeepBoxDeepBoxesBoxesFavoritesStore()
        deepBoxDeepBoxesBoxesFavoritesStore.favorites = [
          ...helpers.boxSortAlphaNum(newValue.boxes),
        ]
      }
    },
  )

  async function fetchOverview(payload: { companyId: string; query?: string }) {
    fetchOverviewPending.value = true
    try {
      const params: OverviewQueryParams = {
        'deepBoxes.boxes.limit': 10,
        'companyId': payload.companyId,
      }
      if (payload.query) {
        params.q = payload.query
      }
      const res = await deepBoxOverviewAPI.get(params)
      overview.value = res.data

      fetchOverviewPending.value = false
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchOverviewPending.value = false
    }
  }

  async function fetchOverviewFull(payload: {
    companyId: string
    query?: string
  }) {
    fetchOverviewFullPending.value = true
    try {
      const res = await fetchOverview({
        companyId: payload.companyId,
        query: payload.query,
      })

      for (const type of types.value) {
        if (type.size === constants.BOXES_TO_SHOW + 1) {
          // TODO: fix no-await-in-loop

          await fetchNextBoxes({ type })
        }
      }

      const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
      const orgToSelect =
        deepAdminUsersMeStore.getUserSelectableOrganizationById(
          res.data.company.companyId,
        )
      if (orgToSelect) {
        deepAdminUsersMeStore.selectedOrganization = orgToSelect
      }

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchOverviewFullPending.value = false
    }
  }

  async function fetchTypeOverview(typeId: string) {
    fetchTypeOverviewPending.value = true
    try {
      const res = await deepBoxOverviewDeepBoxesAPI.get(typeId)
      typeOverview.value = { ...res.data }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchTypeOverviewPending.value = false
    }
  }

  async function fetchNextBoxes(payload: {
    type: DeepBoxOverview
    query?: string
  }) {
    fetchNextBoxesPending.value = true
    try {
      const params = {
        limit: 50,
        offset: payload.type.boxes.length,
        q: payload.query,
      }
      const res = await deepBoxOverviewDeepBoxesAPI.get(
        payload.type.deepBoxNodeId,
        params,
      )
      const resData = res.data

      resData.boxes.forEach((box) => {
        payload.type.boxes.push(box)
      })

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchNextBoxesPending.value = false
    }
  }

  function boxDeadlinesIdx(boxNodeId: string) {
    return overview.value?.deadlines?.boxes.findIndex(
      (deepBoxNodeBox) => deepBoxNodeBox.boxNodeId === boxNodeId,
    )
  }

  function boxFavoritesIdx(boxNodeId: string) {
    return overview.value?.favorites?.boxes.findIndex(
      (deepBoxNodeBox) => deepBoxNodeBox.boxNodeId === boxNodeId,
    )
  }

  function boxQueuesIdx(boxNodeId: string) {
    return overview.value?.queues?.boxes.findIndex(
      (deepBoxNodeBox) => deepBoxNodeBox.boxNodeId === boxNodeId,
    )
  }

  function boxRecentsIdx(boxNodeId: string) {
    return overview.value?.recents?.boxes.findIndex(
      (deepBoxNodeBox) => deepBoxNodeBox.boxNodeId === boxNodeId,
    )
  }

  // add box
  function addBoxToOverviewType(deepBoxNodeId: string, box: Box) {
    if (!overview.value?.deepBoxes) return
    const deepBoxNodeIdx = overview.value?.deepBoxes.findIndex(
      (deepBox) => deepBox.deepBoxNodeId === deepBoxNodeId,
    )
    if (deepBoxNodeIdx !== -1) {
      if (overview.value?.deepBoxes[deepBoxNodeIdx]?.boxes) {
        const boxes = [...overview.value.deepBoxes[deepBoxNodeIdx].boxes]
        boxes.push(box)
        overview.value.deepBoxes[deepBoxNodeIdx].boxes = [
          ...helpers.boxSortAlphaNum(boxes),
        ]
        // increase the size of total boxes
        overview.value.deepBoxes[deepBoxNodeIdx].size += 1
      }
    }
  }

  // remove box
  function removeBoxFromOverviewType(deepBoxNodeId: string, boxNodeId: string) {
    const deepBoxNodeIdx = overview.value?.deepBoxes.findIndex(
      (deepBox) => deepBox.deepBoxNodeId === deepBoxNodeId,
    )
    if (deepBoxNodeIdx !== -1) {
      const deepBoxNodeBoxIdx = overview.value?.deepBoxes[
        deepBoxNodeIdx
      ].boxes.findIndex(
        (deepBoxNodeBox) => deepBoxNodeBox.boxNodeId === boxNodeId,
      )
      if (deepBoxNodeBoxIdx !== -1) {
        overview.value?.deepBoxes[deepBoxNodeIdx].boxes.splice(
          deepBoxNodeBoxIdx,
          1,
        )
        // decrease the size of total boxes
        overview.value.deepBoxes[deepBoxNodeIdx].size -= 1
        if (boxDeadlinesIdx(boxNodeId) !== -1) {
          overview.value?.deadlines.boxes.splice(deepBoxNodeBoxIdx, 1)
        }
        if (boxFavoritesIdx(boxNodeId) !== -1) {
          overview.value?.favorites.boxes.splice(deepBoxNodeBoxIdx, 1)
        }
        if (boxQueuesIdx(boxNodeId) !== -1) {
          overview.value?.queues.boxes.splice(deepBoxNodeBoxIdx, 1)
        }
        if (boxRecentsIdx(boxNodeId) !== -1) {
          overview.value?.recents.boxes.splice(deepBoxNodeBoxIdx, 1)
        }
      }
    }
  }

  // update box
  function updateBoxOnOverviewType(deepBoxNodeId: string, box: Box) {
    const deepBoxNodeIdx = overview.value?.deepBoxes.findIndex(
      (deepBox) => deepBox.deepBoxNodeId === deepBoxNodeId,
    )
    if (deepBoxNodeIdx !== -1) {
      const deepBoxNodeBoxIdx = overview.value?.deepBoxes[
        deepBoxNodeIdx
      ].boxes.findIndex(
        (deepBoxNodeBox) => deepBoxNodeBox.boxNodeId === box.boxNodeId,
      )
      if (deepBoxNodeIdx !== -1) {
        Object.assign(
          overview.value?.deepBoxes[deepBoxNodeIdx].boxes[deepBoxNodeBoxIdx],
          box,
        )
        if (boxDeadlinesIdx(box.boxNodeId) !== -1) {
          Object.assign(
            overview.value?.deadlines?.boxes[boxDeadlinesIdx(box.boxNodeId)],
            box,
          )
        }
        if (boxFavoritesIdx(box.boxNodeId) !== -1) {
          Object.assign(
            overview.value?.favorites?.boxes[boxFavoritesIdx(box.boxNodeId)],
            box,
          )
        }
        if (boxQueuesIdx(box.boxNodeId) !== -1) {
          Object.assign(
            overview.value?.queues?.boxes[boxQueuesIdx(box.boxNodeId)],
            box,
          )
        }

        if (boxRecentsIdx(box.boxNodeId) !== -1) {
          Object.assign(
            overview.value?.recents?.boxes[boxRecentsIdx(box.boxNodeId)],
            box,
          )
        }
      }
    }
  }

  return {
    // state
    overview,
    canOnboard,
    company,
    types,
    sharedBoxes,
    hasShareBoxes,
    recents,
    queues,
    deadlines,
    typeOverview,
    fetchOverviewPending,
    fetchOverviewFullPending,
    fetchNextBoxesPending,
    fetchTypeOverviewPending,
    // actions
    fetchOverview,
    fetchOverviewFull,
    fetchTypeOverview,
    fetchNextBoxes,
    addBoxToOverviewType,
    removeBoxFromOverviewType,
    updateBoxOnOverviewType,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxOverviewStore, import.meta.hot),
  )
}
