import { useTheme } from 'vuetify'
export function useTplColors() {
  const theme = useTheme()
  function getTplColorByName(name: string) {
    const vuetifyCurrentThemeColors = theme.current.value.colors
    return (
      vuetifyCurrentThemeColors[name] || vuetifyCurrentThemeColors['primary']
    )
  }
  return { getTplColorByName }
}
