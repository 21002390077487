import type {
  PollMap,
  PollMapStatus,
} from '@/stores/deepbox/deepboxes/upload.ts'
import { computed } from 'vue'
import { useDeepBoxUploadStore } from '@/stores/deepbox/deepboxes/upload.ts'
import { humanFileSize } from '@/utils/deep'
import { type AxiosError, isAxiosError } from 'axios'
import { AXIOS_ERROR_CODES } from '@/types/axios.ts'
import { useI18n } from 'vue-i18n'

export const POLL_MAP_STATE_ICON = {
  ADDED: '',
  COMPLETED: 'far fa-circle-check',
  PROCESSING: 'far fa-spinner',
  ERROR: 'far fa-circle-xmark',
  WARNING: 'far fa-circle-exclamation',
}

export const POLL_MAP_STATE_COLOR = {
  ADDED: '',
  COMPLETED: 'success',
  PROCESSING: 'blue',
  ERROR: 'error',
  WARNING: 'warning',
}

function getPollMapStateIcon(state: PollMapStatus) {
  switch (state) {
    case 'completed':
      return {
        icon: POLL_MAP_STATE_ICON.COMPLETED,
        color: POLL_MAP_STATE_COLOR.COMPLETED,
      }
    case 'processing':
      return {
        icon: POLL_MAP_STATE_ICON.PROCESSING,
        color: POLL_MAP_STATE_COLOR.PROCESSING,
      }
    case 'warning':
      return {
        icon: POLL_MAP_STATE_ICON.WARNING,
        color: POLL_MAP_STATE_COLOR.WARNING,
      }
    case 'error':
      return {
        icon: POLL_MAP_STATE_ICON.ERROR,
        color: POLL_MAP_STATE_COLOR.ERROR,
      }

    // no default
  }
}

export function useNodeUpload() {
  const deepBoxUploadStore = useDeepBoxUploadStore()
  const { t } = useI18n()

  const pollMapStateColor = computed(() => {
    if (deepBoxUploadStore.pollMapCountProcessing > 0) {
      return POLL_MAP_STATE_COLOR.PROCESSING
    }
    if (
      deepBoxUploadStore.pollMapCountCompleted ===
      deepBoxUploadStore.pollMapCount
    ) {
      return POLL_MAP_STATE_COLOR.COMPLETED
    }

    if (deepBoxUploadStore.pollMapCountWarning > 0) {
      return POLL_MAP_STATE_COLOR.WARNING
    }

    if (deepBoxUploadStore.pollMapCountError > 0) {
      return POLL_MAP_STATE_COLOR.ERROR
    }

    return undefined
  })

  const pollMapStateIcon = computed(() => {
    if (deepBoxUploadStore.pollMapCountProcessing > 0) {
      return POLL_MAP_STATE_ICON.PROCESSING
    }
    if (
      deepBoxUploadStore.pollMapCountCompleted ===
      deepBoxUploadStore.pollMapCount
    ) {
      return POLL_MAP_STATE_ICON.COMPLETED
    }

    if (deepBoxUploadStore.pollMapCountWarning > 0) {
      return POLL_MAP_STATE_ICON.WARNING
    }

    if (deepBoxUploadStore.pollMapCountError > 0) {
      return POLL_MAP_STATE_ICON.ERROR
    }

    return undefined
  })

  function getPollMapIconNode(pollMap: PollMap) {
    if (!pollMap) return undefined
    const foldersCount = pollMap.folders
      ? Object.keys(pollMap.folders).length
      : 0
    const hasMultipleFolders = foldersCount > 0

    const filesCount = pollMap.files ? pollMap.files?.length : 0
    const hasMultipleFiles = filesCount > 1
    if (hasMultipleFolders || hasMultipleFiles) return undefined
    if (filesCount === 1) {
      if (pollMap.files && pollMap.files[0].status === 'completed') {
        return pollMap.files[0].data?.node
      } else {
        return 'unknown'
      }
    }
    return undefined
  }

  function getPollMapTotalSize(pollMap: PollMap) {
    let totalSize = 0
    pollMap.files?.forEach((f) => {
      if (f.data?.file) {
        totalSize += f.data?.file.size
      }
    })

    return totalSize
  }

  function getPollMapTotalSizeDisplay(pollMap: PollMap) {
    return humanFileSize(getPollMapTotalSize(pollMap))
  }

  function getPollMapErrorMsgFormatted(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: string | AxiosError | Error | any,
  ) {
    if (typeof error === 'string' || error instanceof String) return error
    if (isAxiosError(error)) {
      const errorRes = error?.response
      const errorCode = error.code
      const errorResData = errorRes?.data
      const errorResDataMsg = errorResData?.message
      if (errorResDataMsg) return errorResDataMsg
      if (errorCode === AXIOS_ERROR_CODES.ERR_NETWORK) {
        return t('error.error_occurred')
      }
    }
    return error
  }

  return {
    pollMapStateColor,
    pollMapStateIcon,
    getPollMapIconNode,
    getPollMapStateIcon,
    getPollMapTotalSizeDisplay,
    getPollMapErrorMsgFormatted,
  }
}
