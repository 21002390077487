<template>
  <ContainerCenteredFull>
    <AppLoaderAnimation class="d-block" :color="computedColor" />
    <slot></slot>
  </ContainerCenteredFull>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useTplColors } from '@/composables/use-tpl-colors'
import AppLoaderAnimation from '@/components/app/AppLoaderAnimation.vue'
import ContainerCenteredFull from '@/components/containers/ContainerCenteredFull.vue'

const props = defineProps({
  color: {
    type: String,
    default: undefined,
  },
})

const tplColors = useTplColors()

const computedColor = computed(
  () => props.color || tplColors.getTplColorByName('primary'),
)
</script>
