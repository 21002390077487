<template>
  <DeepLoaderAnimation :color="computedColor" />
</template>
<script lang="ts" setup>
import { DeepLoaderAnimation } from '@deepcloud/deep-ui-lib'

const props = defineProps({
  color: {
    type: String,
    default: undefined,
  },
})

import { useTplColors } from '@/composables/use-tpl-colors.ts'
import { computed } from 'vue'

const tplColors = useTplColors()

const computedColor = computed(
  () => props.color || tplColors.getTplColorByName('primary'),
)
</script>
