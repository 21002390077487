<template>
  <div class="download-service">
    <v-snackbar
      v-model="show"
      class="elevation-2"
      :color="background"
      location="bottom left"
      :style="position"
      timeout="-1"
    >
      <div class="d-flex flex-row align-center">
        <v-progress-circular
          v-if="loading"
          class="mr-4"
          :model-value="progress"
          size="20"
        />

        <v-icon v-else-if="!loading && generateSuccess" class="mr-4">
          far fa-check-circle
        </v-icon>

        <div class="grow progress-label-wrapper" style="font-size: 12px">
          <span v-if="loading" class="progress-label">
            {{ t('services.download.snackbar.generate_in_progress') }}
          </span>
          <p v-else-if="!loading && generateSuccess" class="progress-label">
            {{ t('services.download.snackbar.generate_success') }}
          </p>
        </div>
      </div>
    </v-snackbar>
    <DialogSecurityMessage ref="securityMessageDialogRef" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import DialogSecurityMessage from '@/components/dialogs/DialogSecurityMessage.vue'
import { downloadFileByUrlAndName } from '@/utils/deep'
import { constants } from '@/constants/constants'

import { useDeepBoxDownloadStore } from '@/stores/deepbox/download'
import { useSnackbar } from '@/composables/use-snackbar'
import { useI18n } from 'vue-i18n'

const TAG = '[ServiceDownload]'

const { t } = useI18n()

const deepBoxDownloadStore = useDeepBoxDownloadStore()

const loading = ref(false)
const generateSuccess = ref(false)
const pollDownloadInterval = ref(0)
const pollDownloadTime = ref(2000)
const discardAfterCompleted = ref(3000)

const { show, background, position } = useSnackbar()

const progress = computed(
  () => deepBoxDownloadStore.currentDownload?.progress || 0,
)

const currentDownloadStatus = computed(
  () => deepBoxDownloadStore.currentDownload?.status,
)

const isCurrentDownloadStatusReady = computed(
  () => currentDownloadStatus.value === 'ready',
)

const isCurrentDownloadStatusReadyWithIssues = computed(
  () => currentDownloadStatus.value === 'ready-with-issues',
)

const isCurrentDownloadReady = computed(
  () =>
    isCurrentDownloadStatusReady.value ||
    isCurrentDownloadStatusReadyWithIssues.value,
)

const zipName = computed(() => 'Archive.zip')

const zipUrl = computed(() => deepBoxDownloadStore.currentDownload?.downloadUrl)

watch(
  () => deepBoxDownloadStore.currentDownload,
  (newValue, oldValue) => {
    if (!newValue || Object.keys(newValue).length === 0) {
      show.value = false
      loading.value = false
      clearInterval(pollDownloadInterval.value)
      return
    }
    if (newValue.downloadUrl) {
      onDownloadGenerationComplete()
      return
    }
    show.value = true
    loading.value = newValue.progress < 100

    if (!oldValue || Object.keys(oldValue).length === 0) {
      startPollingDownloadStatus()
    }
  },
)

watch(
  () => deepBoxDownloadStore.singleFileDownload,
  (newValue) => {
    if (newValue) {
      onSingleFileSuccess()
    }
  },
)

watch(
  () => isCurrentDownloadReady.value,
  (newValue) => {
    if (newValue) {
      onDownloadGenerationComplete()
    }
  },
)

function onSingleFileSuccess() {
  console.log(
    TAG,
    'creating new single file url download',
    deepBoxDownloadStore.singleFileDownload,
  )

  startBrowserDownload({
    name: deepBoxDownloadStore.singleFileDownload?.node?.displayName,
    url: deepBoxDownloadStore.singleFileDownload?.url,
  })
  deepBoxDownloadStore.setSingleFileDownload(null)
}

function onDownloadGenerationComplete() {
  clearInterval(pollDownloadInterval.value)
  loading.value = false
  if (zipUrl.value) {
    generateSuccess.value = true
    startBrowserDownload({ name: zipName.value, url: zipUrl.value })
  } else {
    generateSuccess.value = false
    show.value = false
  }

  if (discardAfterCompleted.value) {
    setTimeout(discard, discardAfterCompleted.value)
  }
}

function startBrowserDownload({ name, url }) {
  console.log(TAG, 'startBrowserDownload', name, url)
  return downloadFileByUrlAndName(url, name)
}

function startPollingDownloadStatus() {
  console.log(TAG, 'startPollingDownloadStatus')
  clearInterval(pollDownloadInterval.value)
  pollDownloadInterval.value = setInterval(
    pollCurrentDownload,
    pollDownloadTime.value,
  )
}

const securityMessageDialogRef = ref<typeof DialogSecurityMessage | null>(null)

async function pollCurrentDownload() {
  if (!deepBoxDownloadStore.currentDownload) return
  const { downloadId } = deepBoxDownloadStore.currentDownload
  const res = await deepBoxDownloadStore.pollDownload({ downloadId })
  const { status } = res.data
  if (status === constants.DOWNLOAD_API_STATUS_READY_WITH_ISSUES) {
    const { logEntries } = res.data
    const errorMessageIds: string[] = []
    logEntries.forEach((log) => {
      if (!errorMessageIds.includes(log.messageId)) {
        errorMessageIds.push(log.messageId)
      }
    })
    const errorMessageIdsLength = errorMessageIds.length
    if (errorMessageIdsLength > 0) {
      if (
        errorMessageIdsLength === 1 &&
        errorMessageIds[0] === constants.AV_SCAN_VIRUS_FOUND
      ) {
        const msgOptions = {
          title: {
            text: t('services.download.errors.av_scan_virus_found.title'),
          },
          buttons: {
            agree: {
              text: t('services.download.errors.av_scan_virus_found.btn.agree'),
            },
          },
          message: t('services.download.errors.av_scan_virus_found.message'),
          type: 'checkbox',
          checkbox: {
            label: t(
              'services.download.errors.av_scan_virus_found.checkbox.label',
            ),
          },
          logEntries,
          color: 'error',
        }
        await securityMessageDialogRef.value
          ?.open(msgOptions)
          .then(async ({ action }) => {
            if (action) {
              await deepBoxDownloadStore.pollDownload({
                downloadId,
                acceptMessageIds: [constants.AV_SCAN_VIRUS_FOUND],
              })
            }
          })
      }
      if (
        errorMessageIdsLength === 1 &&
        errorMessageIds[0] === constants.AV_SCAN_FILE_TO_BIG
      ) {
        const msgOptions = {
          title: {
            text: t('services.download.errors.av_scan_file_too_big.title'),
          },
          message: t('services.download.errors.av_scan_file_too_big.message'),
          buttons: {
            agree: {
              text: t(
                'services.download.errors.av_scan_file_too_big.btn.agree',
              ),
            },
          },
          color: 'warning',
          logEntries,
        }
        console.log(msgOptions)
        await securityMessageDialogRef.value
          ?.open(msgOptions)
          .then(async (result) => {
            if (result) {
              await deepBoxDownloadStore.pollDownload({
                downloadId,
                acceptMessageIds: [constants.AV_SCAN_FILE_TO_BIG],
              })
            }
          })
      }
      if (
        errorMessageIdsLength === 2 &&
        errorMessageIds.includes(constants.AV_SCAN_FILE_TO_BIG) &&
        errorMessageIds.includes(constants.AV_SCAN_VIRUS_FOUND)
      ) {
        const msgOptions = {
          title: {
            text: t(
              'services.download.errors.av_scan_virus_found_and_file_too_big.title',
            ),
          },
          message: t(
            'services.download.errors.av_scan_virus_found_and_file_too_big.message',
          ),
          buttons: {
            agree: {
              text: t(
                'services.download.errors.av_scan_virus_found_and_file_too_big.btn.agree',
              ),
            },
          },
          type: 'checkbox',
          checkbox: {
            label: t(
              'services.download.errors.av_scan_virus_found_and_file_too_big.checkbox.label',
            ),
          },
          color: 'error',
          logEntries,
        }
        await securityMessageDialogRef.value
          ?.open(msgOptions)
          .then(async ({ action }) => {
            if (action) {
              await deepBoxDownloadStore.pollDownload({
                downloadId,
                acceptMessageIds: [
                  constants.AV_SCAN_FILE_TO_BIG,
                  constants.AV_SCAN_VIRUS_FOUND,
                ],
              })
            }
          })
      }
    }
  }
}

function discard() {
  deepBoxDownloadStore.setCurrentDownload(undefined)
  loading.value = false
  generateSuccess.value = false
  show.value = false
}
</script>
<style lang="scss" scoped>
.progress-label-wrapper {
  a {
    text-decoration: underline;
  }
}
</style>
