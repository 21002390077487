import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { ContentData } from '@/api/types/deepbox/globals'
import type { NodeUpdate } from '@/api/types/deepbox/node'

export default class DeepBoxNodesAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  postById(
    nodeId: string,
    data?: Record<string, unknown>,
    params?: Record<string, unknown>,
  ): Promise<HttpClientResponse<Node[]>> {
    return this.httpClient.post<Node[]>(`nodes/${nodeId}`, data, {
      params,
    })
  }

  getById(
    nodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<ContentData>> {
    return this.httpClient.get<ContentData>(`nodes/${nodeId}`, params, options)
  }

  patchById(
    nodeId: string,
    data?: NodeUpdate,
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.put<never>(`nodes/${nodeId}`, data)
  }

  deleteById(
    nodeId: string,
    params?: Record<string, unknown>,
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.delete<never>(`nodes/${nodeId}`, {
      params,
    })
  }
}

export const deepBoxNodesAPI = new DeepBoxNodesAPIService(deepBoxApi)
