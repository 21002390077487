// Utilities
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import resetStore from './plugins/reset-store'

const pinia = createPinia()
pinia.use(resetStore)
pinia.use(piniaPluginPersistedstate)

export default pinia
