import { useDeepBoxCoreStore } from '@/stores/deepbox/core.ts'
import { useDeepBoxDeepBoxesBoxesFilesTreeStore } from '@/stores/deepbox/deepboxes/boxes/files-tree.ts'
import type { Node } from '@/api/types/deepbox/node.ts'
import type { PathSegment } from '@/api/types/deepbox/path.ts'
import type { SearchItem } from '@/api/types/deepbox/search.ts'
import type { FileTree } from '@/types/file-tree.ts'
import type { Sections } from '@/types/sections.ts'
import { constants } from '@/constants'

export function useFilesTree() {
  const deepBoxCoreStore = useDeepBoxCoreStore()
  const deepBoxDeepBoxesBoxesFilesTreeStore =
    useDeepBoxDeepBoxesBoxesFilesTreeStore()

  async function refreshFilesTreeOnMove(
    parentId?: string,
    targetId?: string,
    target?: Node | PathSegment | SearchItem | FileTree,
  ) {
    // ignore updates of Tree is closed
    if (!deepBoxCoreStore.drawerDisplayTree) return

    const filesNodeId = deepBoxCoreStore.selectedBox?.roots?.files
    const typeId = deepBoxCoreStore.selectedBox?.deepBoxNodeId
    const boxId = deepBoxCoreStore.selectedBox?.boxNodeId

    if (!typeId || !boxId) return

    // update root if needed
    if (
      parentId &&
      (filesNodeId === targetId || filesNodeId === parentId || !target)
    ) {
      await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTree({
        typeId,
        boxId,
      })
    }

    // update source
    if (parentId && filesNodeId !== parentId) {
      const parent =
        deepBoxDeepBoxesBoxesFilesTreeStore.getFileTreeNodeByNodeId(
          parentId,
          deepBoxDeepBoxesBoxesFilesTreeStore.filesTree,
        )
      if (parent && parent.nodeId) {
        await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTreeChildren({
          typeId,
          boxId,
          nodeId: parent.nodeId,
          parent,
        })
      }
    }

    // update target
    if (filesNodeId !== targetId && target && target.nodeId) {
      await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTreeChildren({
        typeId,
        boxId,
        nodeId: target.nodeId,
        parent: target as FileTree,
      })
    }
  }

  async function refreshFilesTreeBySection(
    typeId: string,
    boxId: string,
    section: Sections,
    parentNodeId?: string,
  ) {
    // ignore updates of Tree is closed
    if (!deepBoxCoreStore.drawerDisplayTree) return

    if (section === 'files') {
      await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTree({
        typeId,
        boxId,
      })
    } else if (section === 'fileNodes' && parentNodeId) {
      const parent =
        deepBoxDeepBoxesBoxesFilesTreeStore.getFileTreeNodeByNodeId(
          parentNodeId,
          deepBoxDeepBoxesBoxesFilesTreeStore.filesTree,
        )
      if (parent) {
        await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTreeChildren({
          typeId,
          boxId,
          nodeId: parentNodeId,
          parent,
        })
      }
    }
  }

  /**
   * Helper to search in the store state `filesTree` for the node item ref
   * if no node was found, the given item is returned
   * Will only search if Tree is opened
   * @param item
   */
  function getTreeNodeRefOrGivenItem(
    item: Node | PathSegment | SearchItem | FileTree,
  ) {
    if (deepBoxCoreStore.drawerDisplayTree) {
      const treeNode =
        deepBoxDeepBoxesBoxesFilesTreeStore.getFileTreeNodeByNodeId(
          item.nodeId,
          deepBoxDeepBoxesBoxesFilesTreeStore.filesTree,
        )
      return treeNode || item
    }
    return item
  }

  async function refreshFilesTreeByParentIfExists(
    parentNode: Node | PathSegment | SearchItem | FileTree,
    typeId: string,
    boxId: string,
  ) {
    // ignore updates of Tree is closed
    if (!deepBoxCoreStore.drawerDisplayTree) return

    if (parentNode.mimeType === constants.MIME_TYPE_FILES) {
      // add item to the tree root
      await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTree({
        typeId,
        boxId,
      })
    } else {
      const parent = getTreeNodeRefOrGivenItem(parentNode)

      if (parent) {
        await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTreeChildren({
          typeId,
          boxId,
          nodeId: parent.nodeId,
          parent,
        })
      }
    }
  }

  return {
    refreshFilesTreeOnMove,
    refreshFilesTreeBySection,
    refreshFilesTreeByParentIfExists,
    getTreeNodeRefOrGivenItem,
  }
}
