import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type {
  Overview,
  OverviewQueryParams,
} from '@/api/types/deepbox/overview'

export default class DeepBoxOverviewAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    params: OverviewQueryParams,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<Overview>> {
    return this.httpClient.get<Overview>('overview', params, options)
  }
}

export const deepBoxOverviewAPI = new DeepBoxOverviewAPIService(deepBoxApi)
