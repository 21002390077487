import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { AdminBox, AdminBoxData } from '@/api/types/deepbox/admin'
import type { BoxAdd } from '@/api/types/deepbox/box'

export default class DeepBoxAdminDeepBoxesBoxesAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  post(
    deepBoxNodeId: string,
    data: BoxAdd,
  ): Promise<HttpClientResponse<AdminBox>> {
    return this.httpClient.post<AdminBox>(
      `admin/deepBoxes/${deepBoxNodeId}/boxes`,
      data,
    )
  }

  get(
    deepBoxNodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<AdminBoxData>> {
    return this.httpClient.get<AdminBoxData>(
      `admin/deepBoxes/${deepBoxNodeId}/boxes`,
      params,
      options,
    )
  }

  getById(
    deepBoxNodeId: string,
    boxNodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<AdminBox>> {
    return this.httpClient.get<AdminBox>(
      `admin/deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}`,
      params,
      options,
    )
  }

  deleteById(
    deepBoxNodeId: string,
    boxNodeId: string,
    params?: Record<string, unknown>,
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.delete<never>(
      `admin/deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}`,
      {
        params,
      },
    )
  }

  putById(
    deepBoxNodeId: string,
    boxNodeId: string,
    data: BoxAdd,
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.put<never>(
      `admin/deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}`,
      data,
    )
  }
}

export const deepBoxAdminDeepBoxesBoxesAPI =
  new DeepBoxAdminDeepBoxesBoxesAPIService(deepBoxApi)
