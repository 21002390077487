import { useLocalStorage } from '@vueuse/core'
import { useTheme } from 'vuetify'

export function useAppTheme() {
  const theme = useTheme()
  const currentTheme = useLocalStorage('currentTheme', 'light')

  function isDark() {
    return currentTheme.value === 'dark'
  }

  function toggleTheme() {
    currentTheme.value = isDark() ? 'light' : 'dark'
    setCurrentTheme()
  }

  function setCurrentTheme() {
    theme.global.name.value = currentTheme.value
  }

  return {
    currentTheme,
    isDark,
    toggleTheme,
    setCurrentTheme,
  }
}
