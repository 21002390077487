import { useLocalStorage } from '@vueuse/core'
import type { Node } from '@/api/types/deepbox/node'
import { useMsgBox } from '@deepcloud/deep-ui-lib'
import { deepBoxNodesConvertAPI } from '@/api/deepbox/nodes/nodes-convert'
import { useDeepSign } from '@/composables/use-deep-sign'
import { useI18n } from 'vue-i18n'
import type { PathSegment } from '@/api/types/deepbox/path'
import { toast } from 'vue-sonner'
import { useLoader } from '@deepcloud/deep-ui-lib'
import type { SearchItem } from '@/api/types/deepbox/search'
import {
  isNodeMimeTypeDOCX,
  isNodeMimeTypeXLSX,
} from '@/utils/helpers/nodes.ts'

export function useNodeConvertPdfAndSign() {
  const doNotShowAgainAlertConvertDocxToPdf = useLocalStorage(
    'doNotShowAgainAlertConvertDocxToPdf',
    false,
  )

  const deepSign = useDeepSign()
  const deepMsgBox = useMsgBox()
  const { t } = useI18n()
  const deepLoader = useLoader()

  async function doConvertWordToPdf(node: Node | PathSegment | SearchItem) {
    try {
      const res = await deepBoxNodesConvertAPI.post(node.nodeId)
      return Promise.resolve(res)
    } catch (e) {
      console.error(e)
      const eResData = e?.response?.data
      const responseErrorCode = eResData?.messageId
      if (responseErrorCode === 'error.file.tooBig') {
        const maxFileSize = eResData?.['maxFileSize.mb']
        toast.error(
          t('nodes.convert_to_pdf_and_sign_msg_box.errors.file_to_big_mb', {
            maxFileSize,
          }),
        )
        return Promise.reject(e)
      }
      toast.error(t('error.error_occurred'))
      return Promise.reject(e)
    }
  }

  async function doConvertWordToPdfAndOpenDeepSign(node: Node | PathSegment) {
    try {
      const res = await doConvertWordToPdf(node)
      await deepSign.openDeepSignOrPreviewDocument(res?.data)
    } catch (e) {
      console.error(e)
    }
  }

  async function doConvertWordToPdfAndSign(node: Node | PathSegment) {
    if (!node || (!isNodeMimeTypeDOCX(node) && !isNodeMimeTypeXLSX(node)))
      return

    if (!doNotShowAgainAlertConvertDocxToPdf.value) {
      const msgOptions = {
        title: t('nodes.convert_to_pdf_and_sign_msg_box.title'),
        content: t('nodes.convert_to_pdf_and_sign_msg_box.msg'),
        msgBoxType: 'checkbox',
        msgBoxTypeFormFieldProps: {
          label: t('nodes.convert_to_pdf_and_sign_msg_box.checkbox.label'),
          rules: [],
        },
        btnActions: {
          confirm: {
            title: t('buttons.proceed'),
          },
        },
      }
      const { action, checkbox } = await deepMsgBox(msgOptions)
      doNotShowAgainAlertConvertDocxToPdf.value = checkbox
      if (action === 'confirm') {
        deepLoader.show(
          t('nodes.convert_to_pdf_and_sign_msg_box.msg_converting_file_to_pdf'),
        )
        await doConvertWordToPdfAndOpenDeepSign(node)
        deepLoader.hide()
      }
    } else {
      deepLoader.show(
        t('nodes.convert_to_pdf_and_sign_msg_box.msg_converting_file_to_pdf'),
      )
      await doConvertWordToPdfAndOpenDeepSign(node)
      deepLoader.hide()
    }
  }

  return {
    doConvertWordToPdf,
    doConvertWordToPdfAndSign,
  }
}
