import ruleEmail from './ruleEmail'
import ruleMaxLength from './ruleMaxLength'
import ruleNoComma from './ruleNoComma'
import ruleRequired from './ruleRequired'

export default {
  ruleEmail,
  ruleMaxLength,
  ruleNoComma,
  ruleRequired,
}
