import { computed, ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { DevModeFeatures } from '@/components/dev/const'
import { DEV_MODE_FEATURES } from '@/components/dev/const'
import { isLocalOrDevEnv } from '@/utils/helpers/env.ts'

export const useSettingsDevStore = defineStore(
  'settingsDev',
  () => {
    const devModeEnabled = ref(false)
    const devShowSettings = ref(false)
    const devDebugEnabled = ref(false)
    const devModeFeatures = ref<DevModeFeatures[]>([])

    // be sure that DevMode only works on `local` or `dev` envs

    if (isLocalOrDevEnv()) {
      devShowSettings.value = false
      devModeEnabled.value = true
      devModeFeatures.value = [...DEV_MODE_FEATURES]
    } else {
      devShowSettings.value = false
      devModeEnabled.value = false
    }

    const isDevModeAllowed = computed(() => isLocalOrDevEnv())

    function canModeDev() {
      return isDevModeAllowed.value && devModeEnabled.value
    }

    function hasModeFeature(feature: DevModeFeatures) {
      return devModeFeatures.value.includes(feature)
    }

    function canModeFeature(flag: DevModeFeatures) {
      return canModeDev() && hasModeFeature(flag)
    }

    return {
      isDevModeAllowed,
      devModeEnabled,
      devShowSettings,
      devDebugEnabled,
      devModeFeatures,
      canModeFeature,
    }
  },
  { persist: true },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSettingsDevStore, import.meta.hot))
}
