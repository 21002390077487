<template>
  <div>
    <CoreCommentTextarea
      v-bind="$attrs"
      ref="commentTextareaRef"
      v-model.trim="comment"
      class="mb-2"
      :counter="maxLength"
      :loading="deepBoxCommentsStore.addNodeCommentPending"
      :rules="commentValidationRules"
      @comment:add="onCommentAdd"
      @is-field-focused="isCommentFieldFocused = $event"
    >
      <template v-if="isCommentFieldFocused" #actions>
        <v-spacer />
        <VBtnTertiary name="btn-comments-clear" @click="onCommentCancel">
          {{ t('core.comments.btn.cancel') }}
        </VBtnTertiary>
        <VBtnPrimary
          :disabled="!isValid"
          :loading="deepBoxCommentsStore.addNodeCommentPending"
          name="btn-comments-add"
          @click="onCommentAdd"
        >
          <template #loader>
            <v-progress-circular
              color="white"
              indeterminate
              :size="10"
              :width="1"
            />
          </template>
          {{ t('core.comments.btn.save') }}
        </VBtnPrimary>
      </template>
    </CoreCommentTextarea>

    <v-slide-y-transition class="py-0" group tag="div">
      <div
        v-for="nodeComment in deepBoxCommentsStore.comments"
        :key="nodeComment.commentId"
      >
        <CoreCommentItem
          :comment="Object.assign({}, nodeComment)"
          :highlight="nodeComment.isHighlighted"
          :max-length="maxLength"
          :node-id="nodeId"
          :rules="commentValidationRules"
          @comment:edit="onCommentEdit"
          @comment:update:prop="deepBoxCommentsStore.updateNodeCommentProp"
          @menu:click:remove="onCommentDelete($event)"
        />
      </div>
    </v-slide-y-transition>
    <div
      v-if="deepBoxCommentsStore.size > deepBoxCommentsStore.comments.length"
      class="mt-2 text-center"
    >
      <v-btn
        block
        color="primary"
        :loading="isLoadingMore"
        name="btn-comments-load-more"
        variant="plain"
        @click="onCommentsMoreFetch"
      >
        {{ t('core.comments.load_more') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import debounce from 'lodash/debounce'
import CoreCommentItem from '@/components/core/comments/CoreCommentItem.vue'
import CoreCommentTextarea from '@/components/core/comments/CoreCommentTextarea.vue'

import { useDeepBoxCommentsStore } from '@/stores/deepbox/nodes/comments'
import { useNodeComment } from '@/composables/use-node-comment'
import { useI18n } from 'vue-i18n'
import { toast } from 'vue-sonner'

// utilities
import validationsRules from '@/utils/validations/rules'

const props = defineProps({
  nodeId: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['comment:add'])

const { t } = useI18n()

const commentTextareaRef = ref<typeof CoreCommentTextarea | null>(null)

const deepBoxCommentsStore = useDeepBoxCommentsStore()

const { updateNodeCommentCount } = useNodeComment()

const comment = ref()
const isCommentFieldFocused = ref(false)
const isLoadingMore = ref(false)
const maxLength = ref(1000)

const isValid = computed(
  () => comment.value && comment.value?.length <= maxLength.value,
)
const commentValidationRules = computed(() => [
  (v) => validationsRules.ruleMaxLength(v, { length: maxLength.value }),
])

watch(
  () => props.nodeId,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      commentTextareaRef.value?.clear()
    }
  },
)

watch(
  () => deepBoxCommentsStore.comments,
  (newValue) => {
    debouncedFocusHandle(newValue)
  },
  { deep: true },
)

async function onCommentsFetch() {
  if (props.nodeId) {
    await deepBoxCommentsStore.fetchNodeComments({
      nodeId: props.nodeId,
      offset: deepBoxCommentsStore.offset,
      limit: deepBoxCommentsStore.limit,
    })
  }
}

async function onCommentAdd() {
  if (!comment.value) return
  const text = comment.value
  comment.value = null
  if (!text) return
  try {
    const { data } = await deepBoxCommentsStore.addNodeComment({
      nodeId: props.nodeId,
      text,
    })
    commentTextareaRef.value?.blur()
    startHighlightComment(data.commentId)

    emit('comment:add', data)
    updateNodeCommentCount({
      nodeId: props.nodeId,
      count: deepBoxCommentsStore.comments.length,
    })
  } catch {
    toast.error(t('error.error_occurred'))
  }
}

async function onCommentDelete(comment) {
  try {
    deepBoxCommentsStore.updateNodeCommentProp({
      commentId: comment.commentId,
      prop: { isLoading: true },
    })
    await deepBoxCommentsStore.deleteNodeComment({
      nodeId: props.nodeId,
      commentId: comment.commentId,
    })
    comment.value = null
    updateNodeCommentCount({
      nodeId: props.nodeId,
      count: deepBoxCommentsStore.comments.length,
    })
  } catch {
    toast.error(t('error.error_occurred'))
  } finally {
    deepBoxCommentsStore.updateNodeCommentProp({
      commentId: comment.commentId,
      prop: { isLoading: false },
    })
  }
}

async function onCommentEdit(comment) {
  try {
    deepBoxCommentsStore.updateNodeCommentProp({
      commentId: comment.commentId,
      prop: { isLoading: true },
    })
    await deepBoxCommentsStore.updateNodeComment({
      nodeId: props.nodeId,
      text: comment.text,
      commentId: comment.commentId,
    })
    startHighlightComment(comment.commentId)
  } catch {
    toast.error(t('error.error_occurred'))
  } finally {
    deepBoxCommentsStore.updateNodeCommentProp({
      commentId: comment.commentId,
      prop: { isEditMode: false, isLoading: false },
    })
  }
}

function onCommentCancel() {
  commentTextareaRef.value?.clear()
}

function startHighlightComment(commentId) {
  deepBoxCommentsStore.updateNodeCommentProp({
    commentId,
    prop: { isHighlighted: true },
  })
  setTimeout(() => {
    deepBoxCommentsStore.updateNodeCommentProp({
      commentId,
      prop: { isHighlighted: false },
    })
  }, 3500)
}

async function onCommentsMoreFetch() {
  if (deepBoxCommentsStore.offset > deepBoxCommentsStore.size) {
    deepBoxCommentsStore.updateApiParams({
      offset: deepBoxCommentsStore.size,
      limit: 0,
    })
  } else {
    deepBoxCommentsStore.updateApiParams({
      offset: deepBoxCommentsStore.offset + deepBoxCommentsStore.limit,
      limit: deepBoxCommentsStore.limit,
    })
  }
  isLoadingMore.value = true
  await onCommentsFetch()
  isLoadingMore.value = false
}

const debouncedFocusHandle = debounce(function (value) {
  const textareaEl = commentTextareaRef.value?.$refs?.textareaRef
  if (!textareaEl) return
  if (value.length === 0) {
    textareaEl?.focus()
  } else {
    textareaEl.blur()
  }
}, 200)
</script>
