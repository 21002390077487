import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { SearchData, SearchQueryParams } from '@/api/types/deepbox/search'

export default class DeepBoxSearchAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    params: SearchQueryParams,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<SearchData>> {
    return this.httpClient.get<SearchData>('search', params, options)
  }
}

export const deepBoxSearchAPI = new DeepBoxSearchAPIService(deepBoxApi)
