import i18n from '@/plugins/i18n'
import type { ValidationRuleBaseParams } from './types/validationRuleBaseParams'

export interface RuleMaxLengthParams extends ValidationRuleBaseParams {
  length: number
}

/**
 * Rule validate max length
 * @param {any} v the given value to validate
 * @param {RuleMaxLengthParams} params
 * @returns validate
 */
const validation = (v: number | string, params: RuleMaxLengthParams) => {
  return (
    String(v).length <= params.length ||
    (params?.msg ?? i18n.global.t('validation.max', params.length))
  )
}

export default validation
