<template>
  <div>
    <span v-if="readMoreActivated" v-html="getSanitizedHtml(content)"></span>
    <span v-else v-html="getSanitizedHtml(content.slice(0, 200))" />
    <a @click.stop="readMoreActivated = !readMoreActivated">
      {{ readMoreActivated ? t('labels.less') : t('labels.more') }}
    </a>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { getSanitizedHtml } from '@/utils/helpers/html-sanitize.ts'
import { useI18n } from 'vue-i18n'

defineProps({
  content: {
    type: String,
    default: undefined,
  },
})
const { t } = useI18n()

const readMoreActivated = ref(false)
</script>
