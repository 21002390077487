import axios from 'axios'
import { AxiosHttpClient } from '@/api/http-client'

// create axios
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_DEEPCLOUD_STORAGE_BASE_URL, // api base_url
})

axiosInstance.interceptors.request.use((config) => {
  const headers = {
    common: {
      Accept: 'application/json',
    },
  }
  Object.assign(config.headers, headers)
  return {
    ...config,
  }
})

export default new AxiosHttpClient(axiosInstance)
