import axios, { isAxiosError } from 'axios'
import { setInterceptorHeaderAuthorization } from '@/api/interceptors/utils'
import { toast } from 'vue-sonner'
import { AxiosHttpClient } from '@/api/http-client'

// create axios
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_DEEPBOX_API_BASE_URL, // api base_url
})

const err = (error) => {
  console.error(error)
  if (isAxiosError(error) && error.response) {
    const status = error?.response?.status
    switch (status) {
      case 500:
        if (error.message) {
          toast.error(error.message)
        }
        break
      default:
        break
    }
  }
  return Promise.reject(error)
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return err(error)
  },
)

axiosInstance.interceptors.request.use(async (config) => {
  const headers = {}

  await setInterceptorHeaderAuthorization(headers)

  Object.assign(config.headers, headers)
  return {
    ...config,
  }
})

export default new AxiosHttpClient(axiosInstance)
