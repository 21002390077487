import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { constants } from '@/constants'
import type { RouteLocation } from 'vue-router'
import type { ContentData } from '@/api/types/deepbox/globals'
import { deepBoxDeepBoxesBoxesQueueAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-queue'
import { deepBoxDeepBoxesBoxesTrashAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-trash'
import { deepBoxDeepBoxesBoxesFilesAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-files'
import type { NodeInfo } from '@/api/types/deepbox/node.ts'

export const useDeepBoxPreviewStore = defineStore('deepBoxPreview', () => {
  const parentNodeChildren = ref<ContentData | undefined>(undefined)
  const fetchParentNodeChildrenPending = ref(false)
  const backRoute = ref<RouteLocation>()
  const currentNodeData = ref<NodeInfo>()

  async function fetchParentNodeChildren({
    section,
    typeId,
    boxId,
    nodeId,
    limit = 50,
  }: {
    section: string
    typeId: string
    boxId: string
    nodeId: string
    limit?: number
  }) {
    fetchParentNodeChildrenPending.value = true
    let res
    try {
      const params = {
        limit,
      }
      switch (section) {
        case constants.SECTION_QUEUE:
          res = await deepBoxDeepBoxesBoxesQueueAPI.get(typeId, boxId, params)
          break
        case constants.SECTION_FILES:
          res = await deepBoxDeepBoxesBoxesFilesAPI.get(typeId, boxId, params)
          break
        case constants.SECTION_FILES_NODE:
          res = await deepBoxDeepBoxesBoxesFilesAPI.getById(
            typeId,
            boxId,
            nodeId,
            params,
          )
          break
        case constants.SECTION_TRASH:
          res = await deepBoxDeepBoxesBoxesTrashAPI.get(typeId, boxId, params)
          break
        case constants.SECTION_TRASH_NODE:
          res = await deepBoxDeepBoxesBoxesTrashAPI.getById(
            typeId,
            boxId,
            nodeId,
            params,
          )
          break

        // no default
      }
      if (res) {
        parentNodeChildren.value = { ...res?.data }
      }
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchParentNodeChildrenPending.value = false
    }
  }

  function setBackRoute(route: RouteLocation | undefined) {
    backRoute.value = route
  }

  function setCurrentNodeData(value: NodeInfo) {
    currentNodeData.value = value
  }

  return {
    // state
    fetchParentNodeChildrenPending,
    parentNodeChildren,
    backRoute,
    currentNodeData,
    // actions
    fetchParentNodeChildren,
    setBackRoute,
    setCurrentNodeData,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxPreviewStore, import.meta.hot),
  )
}
