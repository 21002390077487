/**
 * Extract file extension from filename
 * @param filename
 * @returns {string|string}
 */
export const getFileExtension = (filename: string) => {
  if (!filename.includes('.')) {
    return ''
  }
  return (
    filename.substring(filename.lastIndexOf('.') + 1, filename.length) || ''
  )
}

/**
 * Get filename without any file extension
 * @param filename
 * @returns {string}
 */
export const getFilenameWithoutExtension = (filename: string) => {
  if (!filename.includes('.')) {
    return filename
  }
  return filename.split('.').slice(0, -1).join('.')
}

/**
 * Generate filename from name and extension
 * @param name
 * @param extension
 * @returns {string}
 */
export const getNewFileName = (name: string, extension: string) => {
  // hidden files and files without extension
  if (name.substring(0, 1) === '.' || extension === '') {
    return name
  }
  // visible files
  return `${name}.${extension}`
}

/**
 * @param {string} fileUrl
 * @param {string} fileName
 */
export const downloadFileByUrlAndName = (fileUrl: string, fileName: string) => {
  const link = document.createElement('a')
  link.href = fileUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

/**
 * @param {string} path
 */
export const explodePath = (path: string) => {
  const output = path.split('/')
  if (output.length > 0 && output[0].length === 0) {
    output.shift()
  }
  return output
}

/**
 * @param {string} fileName
 */
export const isInvisibleFile = (fileName: string) => {
  return fileName.substr(0, 1) === '.'
}

/**
 * @param {number} bytes
 * @param {number} dp
 */
export const humanFileSize = (bytes: number, dp = 1) => {
  let fileBytes = bytes
  const thresh = 1000

  if (Math.abs(fileBytes) < thresh) {
    return `${fileBytes} Bytes`
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  let u = -1
  const r = 10 ** dp

  do {
    fileBytes /= thresh
    u += 1
  } while (
    Math.round(Math.abs(fileBytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return `${fileBytes.toFixed(dp)} ${units[u]}`
}

/**
 * @param {number} fileSizeLoaded
 * @param {number} fileSizeTotal
 */
export const calculateFileSizePercentage = (
  fileSizeLoaded: number,
  fileSizeTotal: number,
) => {
  if (fileSizeTotal === 0) return undefined
  return Math.round((fileSizeLoaded * 100) / fileSizeTotal)
}

export default {
  getFileExtension,
  getFilenameWithoutExtension,
  getNewFileName,
  downloadFileByUrlAndName,
  explodePath,
  isInvisibleFile,
  humanFileSize,
  calculateFileSizePercentage,
}
