import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type {
  ContentData,
  ContentDataParams,
} from '@/api/types/deepbox/globals'
import type { AxiosRequestConfig } from 'axios'
import type { Node } from '@/api/types/deepbox/node'

export default class DeepBoxDeepBoxesBoxesFilesAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    deepBoxNodeId: string,
    boxNodeId: string,
    params?: ContentDataParams,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<ContentData>> {
    return this.httpClient.get<ContentData>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/files`,
      params,
      options,
    )
  }

  post(
    deepBoxNodeId: string,
    boxNodeId: string,
    formData: FormData,
    config?: AxiosRequestConfig,
  ): Promise<HttpClientResponse<Node[]>> {
    return this.httpClient.post<Node[]>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/files`,
      formData,
      config,
    )
  }

  getById(
    deepBoxNodeId: string,
    boxNodeId: string,
    nodeId: string,
    params?: ContentDataParams,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<ContentData>> {
    return this.httpClient.get<ContentData>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/files/${nodeId}`,
      params,
      options,
    )
  }

  postByNodeId(
    deepBoxNodeId: string,
    boxNodeId: string,
    nodeId: string,
    formData: FormData,
    config?: AxiosRequestConfig,
  ): Promise<HttpClientResponse<Node[]>> {
    return this.httpClient.post<Node[]>(
      `deepBoxes/${deepBoxNodeId}/boxes/${boxNodeId}/files/${nodeId}`,
      formData,
      config,
    )
  }
}

export const deepBoxDeepBoxesBoxesFilesAPI =
  new DeepBoxDeepBoxesBoxesFilesAPIService(deepBoxApi)
