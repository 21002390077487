import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { Box, BoxEntry } from '@/api/types/deepbox/box'
import { deepBoxDeepBoxesBoxesFavoritesAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-favorites'

export const useDeepBoxDeepBoxesBoxesFavoritesStore = defineStore(
  'deepBoxDeepBoxesBoxesFavoritesStore',
  () => {
    const favorites = ref<BoxEntry[]>([])
    const addToFavoritesPending = ref(false)
    const removeFromFavoritesPending = ref(false)

    async function addToFavorites(payload: Box | BoxEntry) {
      addToFavoritesPending.value = true
      const res = await deepBoxDeepBoxesBoxesFavoritesAPI.post(
        payload.deepBoxNodeId,
        payload.boxNodeId,
      )

      favorites.value.push(payload)
      addToFavoritesPending.value = false
      return res
    }

    async function removeFromFavorites(payload: Box | BoxEntry) {
      removeFromFavoritesPending.value = true
      try {
        const res = await deepBoxDeepBoxesBoxesFavoritesAPI.deleteById(
          payload.deepBoxNodeId,
          payload.boxNodeId,
        )

        const index = favorites.value.findIndex(
          (fav) => fav.boxNodeId === payload.boxNodeId,
        )
        if (index > -1) {
          favorites.value.splice(index, 1)
        }
        return Promise.resolve(res)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        removeFromFavoritesPending.value = false
      }
    }

    return {
      // state
      favorites,
      addToFavoritesPending,
      removeFromFavoritesPending,
      // actions
      addToFavorites,
      removeFromFavorites,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxDeepBoxesBoxesFavoritesStore, import.meta.hot),
  )
}
