import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { constants } from '@/constants'
import type { Node } from '@/api/types/deepbox/node'
import { deepBoxNodesDownloadUrlAPI } from '@/api/deepbox/nodes/nodes-download-url'
import type { NodeDownloadUrlQueryParams } from '@/api/types/deepbox/node-download-url'
import { deepBoxDownloadsAPI } from '@/api/deepbox/downloads/downloads'
import type {
  Download,
  DownloadsStatusQueryParams,
} from '@/api/types/deepbox/download'

const TAG = '[deepbox:download:store]'

export interface SingleFileDownload {
  node: Node
  url: string
}

export const useDeepBoxDownloadStore = defineStore('deepBoxDownload', () => {
  const singleFileDownload = ref<SingleFileDownload>()
  const currentDownload = ref<Download>()
  const downloadContentUrlPending = ref(false)
  const downloadFileUrl = ref<string>()

  async function requestDownload(payload: Node[]) {
    console.log(TAG, 'requestDownload', payload)
    const isSingleFile =
      payload.length === 1 && payload[0].type === constants.NODE_TYPE_FILE
    if (isSingleFile) {
      const node = payload[0]
      try {
        const url = await downloadContentUrl({ nodeId: node.nodeId })
        if (url) {
          singleFileDownload.value = { node, url }
        }
      } catch (e) {
        console.error(e)
        return Promise.reject(e)
      }
    } else {
      const nodeIds = payload.map((node) => node.nodeId)
      await postDownloads(nodeIds)
    }
  }

  async function postDownloads(nodeIds: string[]) {
    try {
      console.log(TAG, 'postDownloads -> nodeIds', nodeIds)
      const result = await deepBoxDownloadsAPI.post({ nodes: nodeIds })
      console.log(TAG, 'postDownloads -> result', result)
      currentDownload.value = result.data
      return Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function pollDownload(payload: {
    downloadId: string
    acceptMessageIds?: string[]
  }) {
    try {
      console.log(TAG, 'pollDownload -> downloadId', payload.downloadId)
      const params: DownloadsStatusQueryParams =
        {} as DownloadsStatusQueryParams
      if (payload.acceptMessageIds && payload.acceptMessageIds.length > 0) {
        params.acceptMessageIds = payload.acceptMessageIds.reduce(
          (key, value) => `${key},${value}`,
        )
      }
      const res = await deepBoxDownloadsAPI.getStatus(
        payload.downloadId,
        params,
      )
      console.log(TAG, 'pollDownload -> res', res)
      currentDownload.value = res.data
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function downloadContentUrl(payload: {
    nodeId: string
    acceptMessageIds?: string[]
  }) {
    downloadContentUrlPending.value = true

    try {
      const { nodeId } = payload
      console.log(TAG, 'downloadContentUrl -> payload', payload)
      const params: NodeDownloadUrlQueryParams =
        {} as NodeDownloadUrlQueryParams
      const { acceptMessageIds } = payload
      if (acceptMessageIds && acceptMessageIds.length > 0) {
        params.acceptMessageIds = acceptMessageIds.reduce(
          (key, value) => `${key},${value}`,
        )
      }
      const result = await deepBoxNodesDownloadUrlAPI.get(nodeId, params)
      const url = result.data
      downloadFileUrl.value = url
      console.log(TAG, 'downloadContentUrl -> result', result)
      return url
    } catch (error) {
      await Promise.reject(error)
    } finally {
      downloadContentUrlPending.value = false
    }
  }

  function setSingleFileDownload(payload: SingleFileDownload | null) {
    singleFileDownload.value = payload
  }

  function setCurrentDownload(payload: Download | undefined) {
    currentDownload.value = payload
  }

  return {
    // state
    singleFileDownload,
    currentDownload,
    downloadContentUrlPending,
    downloadFileUrl,
    // actions
    requestDownload,
    postDownloads,
    pollDownload,
    downloadContentUrl,
    setSingleFileDownload,
    setCurrentDownload,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxDownloadStore, import.meta.hot),
  )
}
