import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { Comment, CommentData } from '@/api/types/deepbox/comment'

export default class DeepBoxNodesCommentsAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  post(
    nodeId: string,
    body: string,
    config?: Record<string, unknown>,
  ): Promise<HttpClientResponse<Comment>> {
    return this.httpClient.post<Comment>(
      `nodes/${nodeId}/comments`,
      body,
      config,
    )
  }

  get(
    nodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<CommentData>> {
    return this.httpClient.get<CommentData>(
      `nodes/${nodeId}/comments`,
      params,
      options,
    )
  }

  patchById(
    nodeId: string,
    commentId: string,
    body: string,
    config?: Record<string, unknown>,
  ): Promise<HttpClientResponse<Comment>> {
    return this.httpClient.put<Comment>(
      `nodes/${nodeId}/comments/${commentId}`,
      body,
      config,
    )
  }

  deleteById(
    nodeId: string,
    commentId: string,
  ): Promise<HttpClientResponse<never>> {
    return this.httpClient.delete<never>(
      `nodes/${nodeId}/comments/${commentId}`,
    )
  }

  getById(
    nodeId: string,
    commentId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<Comment>> {
    return this.httpClient.get<Comment>(
      `nodes/${nodeId}/comments/${commentId}`,
      params,
      options,
    )
  }
}

export const deepBoxNodesCommentsAPI = new DeepBoxNodesCommentsAPIService(
  deepBoxApi,
)
