export function convertOrderString(str: string, reverse: boolean) {
  const map1 = ['modified.time', 'size']
  const map2 = ['modifiedTime', 'fileSize']

  let mapInput
  let mapOutput

  if (reverse) {
    mapInput = map1
    mapOutput = map2
  } else {
    mapInput = map2
    mapOutput = map1
  }

  const index = mapInput.findIndex((item) => item === str)
  return index >= 0 ? mapOutput[index] : str
}

export function sortStringToObject(str: string) {
  if (!str) return {}
  const temp = str.split(' ')
  const sortBy = convertOrderString(temp[0], false)
  const sortDesc = temp[1] === 'desc'
  return {
    sortBy: [sortBy],
    sortDesc: [sortDesc],
  }
}

// VDataTable
export function sortStringToArray(str: string) {
  if (!str) return {}
  const temp = str.split(' ')
  return [
    {
      key: convertOrderString(temp[0], false),
      order: temp[1],
    },
  ]
}
