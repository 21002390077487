// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import isEmpty from 'lodash/isEmpty'
import { convertOrderString, explodePath, isInvisibleFile } from '@/utils/deep/'
import { constants } from '@/constants/constants'
import type { ContentDataParams } from '@/api/types/deepbox/globals'
import type { RouteLocation } from 'vue-router'
import { type Sections } from '@/types/sections.ts'
import type { Box, BoxEntry } from '@/api/types/deepbox/box.ts'

export interface UploadEntriesOutputFile {
  file: File
  fileEntry: FileSystemFileEntry
  idFolder?: string
}

export interface UploadEntriesOutputFolder {
  name: string
  parent?: string
}

export interface UploadEntriesOutput {
  files: UploadEntriesOutputFile[]
  folders: Record<string, UploadEntriesOutputFolder>
  hasFolders: boolean
}

export default {
  getSection(path) {
    if (isEmpty(path)) {
      return ''
    }
    const sectionSegment = path.segments[0]
    const isSectionFolder = path.segments.length > 1

    switch (sectionSegment?.mimeType) {
      case constants.MIME_TYPE_FILES:
        if (isSectionFolder) {
          return constants.SECTION_FILES_NODE
        }
        return constants.SECTION_FILES

      case constants.MIME_TYPE_TRASH:
        if (isSectionFolder) {
          return constants.SECTION_TRASH_NODE
        }
        return constants.SECTION_TRASH

      case constants.MIME_TYPE_INBOX:
        return constants.SECTION_QUEUE
      default:
        return ''
    }
  },
  getCurrentSection(routeName: string | null) {
    switch (routeName) {
      case constants.ROUTE_FILES_NODE:
        return constants.SECTION_FILES_NODE
      case constants.ROUTE_FILES:
        return constants.SECTION_FILES
      case constants.ROUTE_TRASH_NODE:
        return constants.SECTION_TRASH_NODE
      case constants.ROUTE_TRASH:
        return constants.SECTION_TRASH
      case constants.ROUTE_QUEUE:
        return constants.SECTION_QUEUE
      default:
        return null
    }
  },
  getAllFileEntriesFromButtonUpload(e: Event) {
    const target = e.target as HTMLInputElement
    const { files } = target

    if (files === null) return null

    const output: UploadEntriesOutput = {
      files: [],
      folders: {},
      hasFolders: false,
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      // if (file.size > constants.UPLOAD_MAX_SIZE) {
      //   return null;
      // }

      let levelFile = 0

      if (!isInvisibleFile(file?.name)) {
        const path = explodePath(file.webkitRelativePath)
        console.log(
          'getAllFileEntriesFromButtonUpload iterating ----',
          file.webkitRelativePath,
          path,
          path.length,
          file.name,
        )
        if (path.length > 0) {
          path.pop()
        }
        levelFile = path.length - 1
        path.forEach((nameFolder: string, level: number) => {
          let idFolder
          if (level > 0) {
            const levelFolderNames = []
            for (let a = 0; a < level + 1; a++) {
              levelFolderNames.push(path[a])
            }
            idFolder = `${level}-${levelFolderNames.join('-')}`
          } else {
            idFolder = `${level}-${nameFolder}`
          }
          let parentId
          if (level === 0) {
            parentId = null
          } else {
            const levelFolderNames = []
            for (let a = 0; a < level; a++) {
              levelFolderNames.push(path[a])
            }
            parentId = `${level - 1}-${levelFolderNames.join('-')}`
          }
          if (!output.folders[idFolder]) {
            output.hasFolders = true
            output.folders[idFolder] = {
              parent: parentId,
              name: nameFolder,
            }
          }
        })
        const levelFolderNames = []
        for (let a = 0; a < levelFile + 1; a++) {
          levelFolderNames.push(path[a])
        }
        let idFolder
        if (levelFolderNames.length > 0) {
          idFolder = `${levelFile}-${levelFolderNames.join('-')}`
        }
        //console.log("file", i, file.webkitRelativePath, file.name, path, folder)
        output.files.push({
          file,
          idFolder,
        })
      }
    }
    return output
  },
  async getAllFileEntries(event: DragEvent) {
    const { dataTransfer } = event
    if (dataTransfer === null) return []
    const dataTransferItemList = dataTransfer.items

    const outputItems: UploadEntriesOutput[] = []
    const queue = []
    const array = []

    for (let i = 0; i < dataTransferItemList.length; i++) {
      const entry = dataTransferItemList[i].webkitGetAsEntry()
      if (entry) {
        array.push(entry)
      }
    }

    const promises = array.map(this.getFile)
    const result = await Promise.all(promises)
    for (let i = 0; i < result.length; i++) {
      queue.push({
        file: result[i],
        fileEntry: array[i],
      })
    }

    const addEntriesToExistingOutputEntry = async (
      dirEntries,
      outputEntry: UploadEntriesOutput,
      parentId: string,
    ) => {
      console.log(
        'addEntriesToExistingOutputEntry',
        dirEntries,
        outputEntry,
        parentId,
      )
      for (const dirEntry of dirEntries) {
        const dirEntryFullPath = explodePath(dirEntry.fullPath)
        const dirEntryLevel = dirEntryFullPath.length
        const dirEntryIdFolder = `${dirEntryLevel - 1}-${dirEntryFullPath.join(
          '-',
        )}`
        if (dirEntry.isDirectory) {
          // add current directory
          outputEntry.folders[dirEntryIdFolder] = {
            parent: parentId,
            name: dirEntry.name,
          }

          const dirEntryChildrenReader = dirEntry.createReader()
          //wait for the directory content
          const dirEntryChildrenEntries = await this.readAllDirectoryEntries(
            dirEntryChildrenReader,
          )

          if (dirEntryChildrenEntries.length > 0) {
            await addEntriesToExistingOutputEntry(
              dirEntryChildrenEntries,
              outputEntry,
              dirEntryIdFolder,
            )
          }
        } else if (dirEntry.isFile && !isInvisibleFile(dirEntry.name)) {
          const file = await this.getFile(dirEntry)
          outputEntry.files.push({
            file,
            fileEntry: dirEntry,
            idFolder: parentId,
          })
        }
      }
    }

    const addUploadEntryOutput = async (entry: FileSystemEntry) => {
      console.log('addUploadEntryOutput', entry)
      if (!entry) return

      const fullPath = explodePath(entry.fullPath)
      const level = fullPath.length
      const isSingleEntry = fullPath.length === 1

      let folderId
      if (entry.isDirectory && !isInvisibleFile(entry.name)) {
        folderId = `${level}-${fullPath.join('-')}`
      }

      if (entry.isFile && !isInvisibleFile(entry.name)) {
        if (isSingleEntry) {
          const file = await this.getFile(entry)
          outputItems.push({
            files: [
              {
                file,
                fileEntry: entry,
              },
            ],
            folders: {},
            hasFolders: false,
          })
          return Promise.resolve()
        }
      } else if (entry.isDirectory) {
        const reader = entry.createReader()
        const nameFolder = entry.name

        //wait for the directory content
        const dirEntries = await this.readAllDirectoryEntries(reader)

        if (dirEntries.length === 0 && level === 1) {
          outputItems.push({
            files: [],
            folders: {
              [folderId]: {
                parent: null,
                name: nameFolder,
              },
            },
            hasFolders: true,
          })
          return Promise.resolve()
        }

        const outputEntry: UploadEntriesOutput = {
          files: [],
          folders: {
            [folderId]: {
              parent: null,
              name: nameFolder,
            },
          },
          hasFolders: true,
        }

        await addEntriesToExistingOutputEntry(dirEntries, outputEntry, folderId)
        outputItems.push(outputEntry)
      }
    }

    while (queue.length > 0) {
      const entry = queue.shift()

      await addUploadEntryOutput(entry.fileEntry)
    }

    console.log('getAllFileEntries', outputItems)
    return outputItems
  },
  async readAllDirectoryEntries(directoryReader: FileSystemDirectoryReader) {
    const entries = []
    let readEntries = await this.readEntriesPromise(directoryReader)
    //console.log("readEntries", readEntries);
    while (readEntries.length > 0) {
      entries.push(...readEntries)
      // TODO: fix no-await-in-loop

      readEntries = await this.readEntriesPromise(directoryReader)
    }
    return entries
  },
  // Wrap readEntries in a promise to make working with readEntries easier
  async readEntriesPromise(directoryReader: FileSystemDirectoryReader) {
    try {
      return await new Promise((resolve, reject) => {
        directoryReader.readEntries(resolve, reject)
      })
    } catch (err) {
      console.log(err)
      return Promise.reject(err)
    }
  },
  // Converts a file entry into a File ready to be uploaded
  getFile(fileEntry: FileSystemFileEntry) {
    //console.log("[GetFile]", fileEntry);
    return new Promise((resolve, reject) => {
      if (fileEntry.isDirectory) {
        resolve(null)
      }

      fileEntry.file(resolve, reject)
    })
  },
  getSectionFromRoute(route: RouteLocation) {
    switch (route.name) {
      case constants.ROUTE_QUEUE:
        return constants.SECTION_QUEUE
      case constants.ROUTE_FILES:
        return constants.SECTION_FILES
      case constants.ROUTE_FILES_NODE:
        return constants.SECTION_FILES_NODE
      case constants.ROUTE_TRASH:
        return constants.SECTION_TRASH
      case constants.ROUTE_TRASH_NODE:
        return constants.SECTION_TRASH_NODE
      default:
        return null
    }
  },
  getUploadSectionFromMimeType(mime: string): Sections {
    switch (mime) {
      case constants.MIME_TYPE_INBOX:
        return 'queue'
      case constants.MIME_TYPE_FILES:
        return 'files'
      case constants.MIME_TYPE_TRASH:
        return 'trash'
      default:
        // there is no possible to upload files to trash
        return 'fileNodes'
    }
  },
  getRouteNameBySectionName(section: Sections) {
    switch (section) {
      case 'inbox':
      case 'queue':
        return 'organization-types-type-boxes-box-inbox'
      case 'files':
        return 'organization-types-type-boxes-box-files'
      case 'fileNodes':
        return 'organization-types-type-boxes-box-files-node'
      case 'trash':
        return 'organization-types-type-boxes-box-trash'
      case 'trashNodes':
        return 'organization-types-type-boxes-box-trash-node'
      default:
        return undefined
    }
  },
  boxSortAlphaNum(boxes: Box[] | BoxEntry[]) {
    return boxes.sort((a, b) => {
      const boxAName = a.boxName || a.name
      const boxBName = b.boxName || b.name
      return boxAName.localeCompare(boxBName, undefined, { numeric: true })
    })
  },
  /**
   * Get sorter from params.order attribute of a node state
   * @param {string} order
   * @returns {string}
   */
  getSorter(order) {
    const sortBy = order.sortBy[0]
    // select the right sorting function
    let sorter
    switch (sortBy) {
      case 'size': // if sorting property is a number
        sorter = (item) => item[sortBy]
        break // if sorting property is a date
      case 'modified.time':
        sorter = (item) => new Date(item.modified.time)
        break
      default:
        // default: lowercase string
        sorter = (item) => item[sortBy].toLowerCase()
    }
    return sorter
  },
  getFetchParameters(payload): ContentDataParams {
    // create the object with default values

    const output = {
      limit: payload.limit || constants.ITEMS_PER_PAGE,
    }
    // list of accepted properties, to override the default object
    const accepted = ['limit', 'offset']

    accepted.forEach((k) => {
      if (payload[k]) {
        output[k] = payload[k]
      }
    })

    // if sortBy property is set, create a corresponding 'order' query string
    if (payload.sortBy && payload.sortBy.length > 0) {
      const sort = []
      if (payload.sortBy[0].key) {
        sort[0] = convertOrderString(payload.sortBy[0].key, true)
      } else {
        sort[0] = convertOrderString(payload.sortBy[0], true)
      }
      if (payload.sortBy[0].order) {
        sort[1] = payload.sortBy[0].order
      } else {
        sort[1] = payload.sortDesc[0] === true ? 'desc' : 'asc'
      }
      output.order = `${sort.join(' ')}`
    }

    return output
  },
  typesSortAlphaNum(types) {
    return types.sort((a, b) =>
      a.deepBoxName.localeCompare(b.deepBoxName, undefined, {
        numeric: true,
      }),
    )
  },
  getReadableName(node, i18n) {
    if (!i18n) {
      console.log('[i18n] instance was not provided ')
      return ''
    }
    if (node) {
      switch (node?.mimeType) {
        case constants.MIME_TYPE_INBOX:
          return i18n.t('navigation.tabs.inbox')
        case constants.MIME_TYPE_FILES:
          return i18n.t('navigation.tabs.files')
        case constants.MIME_TYPE_TRASH:
          return i18n.t('navigation.tabs.trash')
        default:
          return node?.displayName || node?.name
      }
    }
    return undefined
  },
  showVerificationAlert(selectedOrganization, organizations) {
    if (!selectedOrganization) return undefined
    const currentOrganization = this.currentOrganization(
      selectedOrganization,
      organizations,
    )
    if (isEmpty(currentOrganization)) {
      return false
    }
    const isOrgOnboardedDeepV =
      currentOrganization?.metadata?.onboarded_with ===
      constants.ONBOARDING_QUERY_TYPE_DEEPV
    const isOrgOnboardedDeepSign =
      currentOrganization?.metadata?.onboarded_with ===
      constants.ONBOARDING_QUERY_TYPE_DEEPSIGN
    return (
      currentOrganization.roles.includes(constants.USER_ROLE_MANAGE_COMPANY) &&
      !constants.VERIFIED_VERIFICATION_STATES.includes(
        currentOrganization.verification_state,
      ) &&
      !isOrgOnboardedDeepV &&
      !isOrgOnboardedDeepSign
    )
  },
  isPersonalStructure(organization) {
    if (!organization) return undefined
    return constants.COMPANY_PERSONAL_STRUCTURE_TYPES.includes(
      organization.structure,
    )
  },
  isOwnedOrganization(organization) {
    if (!organization) return undefined
    const ownerRoles = [
      constants.USER_ROLE_MANAGE_COMPANY,
      constants.USER_ROLE_MANAGE_MEMBERS,
      constants.USER_ROLE_MANAGE_BOXES,
      constants.USER_ROLE_VIEW_COMPANY,
      constants.USER_ROLE_ORDER_SUB,
    ]
    const organizationRoles = organization?.roles || []
    return ownerRoles.every((role) => organizationRoles.includes(role))
  },
  isPersonalSpace(organization) {
    if (!organization) return undefined
    return (
      this.isOwnedOrganization(organization) &&
      this.isPersonalStructure(organization)
    )
  },
  currentOrganization(selectedOrganization, organizations) {
    if (!selectedOrganization) return undefined
    return organizations.find(
      (organization) => organization.group_id === selectedOrganization.group_id,
    )
  },
  getDeepEditorUrl({
    analysisStatus,
    nodeId,
    deepBoxNodeId,
    groupId,
    boxNodeId,
  }) {
    if (analysisStatus === constants.ANALYSIS_STATUS_RUNNING) {
      return '#'
    }
    const hostOrigin = window.location.origin
    const editorUrl = `${
      import.meta.env.VITE_DEEPBOX_EDITOR_BASE_URL
    }${groupId}/extract/${deepBoxNodeId}/${boxNodeId}/${nodeId}`
    const backLink = `?backLink=${hostOrigin}/${groupId}/types/${deepBoxNodeId}/boxes/${boxNodeId}/inbox`
    return editorUrl + backLink
  },
  hasUserRole(userRole, organization) {
    const organizationRoles = organization?.roles || []
    return organizationRoles.includes(userRole)
  },
  /**
   * Sort nodes in order to have folders always first
   * @param nodes
   * @return [Object]
   */
  getFoldersFirst(nodes) {
    const folders = []
    const files = []

    nodes.forEach((node) => {
      if (node.type === constants.NODE_TYPE_FOLDER) {
        folders.push(node)
      } else {
        files.push(node)
      }
    })

    return folders.concat(files)
  },
}
