import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useKeycloak } from '@/composables/auth/use-keycloak'
import { useMsgBox } from '@deepcloud/deep-ui-lib'

const ERROR_CODES = {
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  LOGGED_OUT: 'LOGGED_OUT',
}

export function useDialogSessionInvalid() {
  const { t } = useI18n()
  const deepMsgBox = useMsgBox()

  const errorCode = ref(ERROR_CODES.LOGGED_OUT)
  const { updateToken, listenIfSessionIdCookieExists, addListener } =
    useKeycloak()

  const msgI18nByErrorCode = computed(() => {
    switch (errorCode.value) {
      case ERROR_CODES.LOGGED_OUT:
        return t('dialogs.session_invalid.loggedout.msg')
      case ERROR_CODES.SESSION_EXPIRED:
        return t('dialogs.session_invalid.session_expired.msg')
      default:
        return ''
    }
  })

  const titleI18nByErrorCode = computed(() => {
    switch (errorCode.value) {
      case ERROR_CODES.LOGGED_OUT:
        return t('dialogs.session_invalid.loggedout.title')
      case ERROR_CODES.SESSION_EXPIRED:
        return t('dialogs.session_invalid.session_expired.title')
      default:
        return ''
    }
  })

  function showMsgBox() {
    const msgOptions = {
      title: titleI18nByErrorCode.value,
      content: msgI18nByErrorCode.value,
      btnActions: {
        confirm: {
          title: t('dialogs.session_invalid.btn_login'),
        },
      },
      closable: false,
      btnActionsShowOnly: ['confirm'],
    }
    deepMsgBox(msgOptions).then(async ({ action }) => {
      if (action === 'confirm') {
        refresh()
      }
    })
  }

  addListener('onTokenExpired', onTokenExpired)
  addListener('onAuthLogout', onAuthLogout)
  ;(async () => {
    listenIfSessionIdCookieExists(async ({ oldValue, newValue }) => {
      if (!newValue && oldValue) {
        await forceAuthServiceUpdateToken()
      } else if (newValue && !oldValue) {
        // this is need for the case if some tab logged in
        refresh()
      }
    }, 1000)
  })()

  async function onTokenExpired() {
    try {
      await updateToken()
    } catch (e) {
      console.error('Error on update token.', e)
      errorCode.value = ERROR_CODES.SESSION_EXPIRED
      showMsgBox()
    }
  }

  function onAuthLogout() {
    errorCode.value = ERROR_CODES.LOGGED_OUT
    showMsgBox()
  }

  async function forceAuthServiceUpdateToken() {
    // with this "hack" the minValidity is bigger than the refreshToken
    // this will result in a "hard" token refresh
    // KC will emit the Events if something is wrong with the session
    try {
      await updateToken(7000)
    } catch (e) {
      console.log(e)
    }
  }

  function refresh() {
    return window.location.reload()
  }
}
