import { ref, watch } from 'vue'

export function useDialogAppInfo() {
  const showDialogAppInfo = ref(false)
  const clickShowDialogAppInfoCounter = ref(0)

  function onClickShowDialogAppInfo() {
    clickShowDialogAppInfoCounter.value += 1
    if (clickShowDialogAppInfoCounter.value === 10) {
      showDialogAppInfo.value = true
    }
  }

  watch(
    () => showDialogAppInfo.value,
    (newValue) => {
      if (!newValue) {
        clickShowDialogAppInfoCounter.value = 0
      }
    },
  )

  return {
    showDialogAppInfo,
    onClickShowDialogAppInfo,
  }
}
