import { ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type { NodeInfo } from '@/api/types/deepbox/node'
import { deepBoxNodesInfoAPI } from '@/api/deepbox/nodes/nodes-info'

export const useDeepBoxNodesInfoStore = defineStore('deepBoxNodesInfo', () => {
  const fetchNodeInfoPending = ref(false)
  const nodeInfo = ref<NodeInfo>()

  async function fetchNodeInfo(nodeId: string) {
    fetchNodeInfoPending.value = true
    try {
      const res = await deepBoxNodesInfoAPI.get(nodeId)
      nodeInfo.value = { ...res.data }
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchNodeInfoPending.value = false
    }
  }

  return {
    // state
    fetchNodeInfoPending,
    nodeInfo,
    // actions
    fetchNodeInfo,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxNodesInfoStore, import.meta.hot),
  )
}
