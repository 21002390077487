import { useLocale } from 'vuetify'
import { useI18n } from 'vue-i18n'
import { useDeepAdminUsersMeLanguageStore } from '@/stores/deepadmin/users/users-me-language.ts'
import { toast } from 'vue-sonner'
import { setApisAcceptLanguageHeader } from '@/api/interceptors/api-instances'
import { LS_LOCALE_KEY } from '@/constants/auth'
import { useKeycloak } from '@/composables/auth/use-keycloak'

export function getAppLocaleFormatted(
  locale: string,
  availableLocales: string[],
  fallbackLocale = 'de',
) {
  if (!locale) {
    locale = fallbackLocale
  }
  // 1. locale is only 2 chars (de, en, fr, it)
  const localeLowerCaseIdx = availableLocales.findIndex((a) =>
    a.startsWith(locale),
  )
  if (localeLowerCaseIdx !== -1) {
    return availableLocales[localeLowerCaseIdx]
  }
  // 2. locale has wrong format (de-ch, en-gb (or en-us), fr-ch, it-ch)
  // or locale has wrong format (deCH, enGB (or enUS), frCH, itCH)
  const localeWrongFormatWithoutHyphenMinusIdx = locale.indexOf('-')
  if (localeWrongFormatWithoutHyphenMinusIdx !== -1) {
    const localeLowerCaseIdx = availableLocales.findIndex(
      (a) =>
        a.toLowerCase().substring(0, 2) ===
        locale.toLowerCase().substring(0, 2),
    )
    if (localeLowerCaseIdx !== -1) {
      return availableLocales[localeLowerCaseIdx]
    }
  } else {
    const localeLowerCaseIdx = availableLocales.findIndex(
      (a) =>
        a.replace('-', '').substring(0, 2) ===
        locale.toLowerCase().substring(0, 2),
    )
    if (localeLowerCaseIdx !== -1) {
      return availableLocales[localeLowerCaseIdx]
    }
  }

  // if not match, returns the app default locale
  return fallbackLocale
}

export function useAppLocale() {
  const { current: localeVuetify } = useLocale()
  const { locale: localeI18n, availableLocales } = useI18n()
  const { isAuthenticated, updateToken } = useKeycloak()

  const _setPluginsLocale = (locale: string) => {
    const localeFormatted = getAppLocaleFormatted(locale, availableLocales)
    if (!localeFormatted) return
    localeI18n.value = localeFormatted
    localeVuetify.value = localeFormatted
    setApisAcceptLanguageHeader(localeFormatted)
    document.documentElement.lang = localeFormatted
    localStorage.setItem(`${LS_LOCALE_KEY}`, localeFormatted)
    console.log('current locale: ', localeFormatted)
  }

  const deepAdminUsersMeLanguageStore = useDeepAdminUsersMeLanguageStore()

  function changeAppLocale(locale: string) {
    console.log('Change APP locale: ', locale)
    _setPluginsLocale(locale)
  }

  async function setAppLocale(locale: string) {
    changeAppLocale(locale)

    if (isAuthenticated.value) {
      try {
        const localeIsoCode = getAppLocaleFormatted(locale, availableLocales)
        await deepAdminUsersMeLanguageStore.storeUserLanguage(localeIsoCode)
        await updateToken()
      } catch {
        toast.error('Error on set user lang')
      }
    }
  }

  function getLanguageTextByIsoCode(isoCode: string) {
    switch (isoCode) {
      case 'de':
        return 'Deutsch'
      case 'en':
        return 'English'
      case 'fr':
        return 'Français'
      case 'it':
        return 'Italiano'
      default:
        return ''
    }
  }

  return {
    changeAppLocale,
    setAppLocale,
    getLanguageTextByIsoCode,
  }
}
