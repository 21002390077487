import type { Box, BoxInfo } from '@/api/types/deepbox/box'
import type { ComputedRef } from 'vue'
import { computed } from 'vue'

export function useBox(box: ComputedRef<Box | BoxInfo>) {
  const isBoxVariantAdvanced = computed(
    () => box.value.boxVariant === 'advanced',
  )

  const isBoxVariantAdvancedPerUse = computed(
    () => box.value.boxVariant === 'advanced-per-use',
  )

  return {
    isBoxVariantAdvanced,
    isBoxVariantAdvancedPerUse,
  }
}
