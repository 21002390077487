import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'

export function useOrganization() {
  function selectOrg(orgId: string, organizations?: UserMeOrganization[]) {
    const deepAdminUsersMeStore = useDeepAdminUsersMeStore()

    // Check if the deepbox company id is part of user's organizations
    let org: UserMeOrganization | undefined
    if (organizations && organizations?.length > 0) {
      org = organizations.find((o) => o.group_id === orgId)
    } else {
      org = deepAdminUsersMeStore.getUserSelectableOrganizations.find(
        (o) => o.group_id === orgId,
      )
    }

    if (org) {
      deepAdminUsersMeStore.selectedOrganization = {
        ...org,
      }
    } else {
      deepAdminUsersMeStore.selectedOrganization =
        deepAdminUsersMeStore.getUserSelectableOrganizations[0]
    }
    return deepAdminUsersMeStore.selectedOrganizationId
  }

  return {
    selectOrg,
  }
}
