import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { BoxInfo } from '@/api/types/deepbox/box'

export default class DeepBoxBoxesInfoAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    boxNodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<BoxInfo>> {
    return this.httpClient.get<BoxInfo>(
      `boxes/${boxNodeId}/info`,
      params,
      options,
    )
  }
}

export const deepBoxBoxesInfoAPI = new DeepBoxBoxesInfoAPIService(deepBoxApi)
