<template>
  <v-fab
    v-bind="$attrs"
    class="preview-action-fab-btn bg-grey-lighten-5"
    icon
    location="top"
    :ripple="false"
    size="small"
    tile
    variant="text"
  >
    <slot></slot>
  </v-fab>
</template>
<style scoped>
.preview-action-fab-btn {
  border: 1px solid #dcdce2;
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  & :deep(.v-fab__container) {
    position: relative;
  }
}
</style>
