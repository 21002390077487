<template>
  <CoreMenuNested
    v-bind="$attrs"
    ref="menuRef"
    density="compact"
    :items="items"
  >
    <template #activator="activator">
      <v-btn
        v-bind="activator.props"
        dense
        icon="far fa-ellipsis-vertical"
        name="deep-a-chat-options-menu-btn"
      >
      </v-btn>
    </template>
  </CoreMenuNested>
</template>

<script setup lang="ts">
import CoreMenuNested from '@/components/core/CoreMenuNested.vue'
import { computed } from 'vue'
import { useAppLocale } from '@/composables/use-app-locale.ts'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  currentLocale: {
    type: String,
    default: 'de',
    validator: (value: string) => {
      return ['de', 'en', 'it', 'fr'].includes(value)
    },
  },
  currentModel: {
    type: String,
    default: undefined,
  },
})

const { t } = useI18n()
const availableLocales = computed(() => ['de', 'en', 'it', 'fr'])

const { getLanguageTextByIsoCode } = useAppLocale()

const items = computed(() => {
  let items = []

  const itemsLang = [
    {
      id: 'lang',
      title: t('actions.language'),
      icon: 'far fa-earth-europe',
      show: true,
      children: availableLocales.value.map((l) => {
        return {
          id: `lang-${l}`,
          value: l,
          title: getLanguageTextByIsoCode(l),
          show: true,
          groupId: 'lang',
          action: {
            icon: props.currentLocale === l ? 'far fa-check' : '',
          },
        }
      }),
    },
  ]

  items = [...items, ...itemsLang]

  const itemsModel = [
    {
      id: 'model',
      title: 'Model',
      icon: 'far fa-wrench',
      value: 'model',
      show: true,
      children: [
        {
          id: 'OPEN_AI',
          value: 'OPEN_AI',
          title: 'OpenAI',
        },
        {
          id: 'OPEN_AI_4',
          value: 'OPEN_AI_4',
          title: 'OpenAI 4',
        },
        { id: 'AZURE_AI', value: 'AZURE_AI', title: 'Azure' },
        {
          id: 'AZURE_AI_4',
          value: 'AZURE_AI_4',
          title: 'Azure 4',
        },
        {
          id: 'ARTIFICIALY',
          value: 'ARTIFICIALY',
          title: 'Artificialy',
        },
      ].map((l) => {
        return {
          ...l,
          groupId: 'model',
          action: {
            icon: props.currentModel === l.value ? 'far fa-check' : '',
          },
        }
      }),
    },
  ]

  items = [...items, ...itemsModel]
  return items
})
</script>
