import * as Sentry from '@sentry/vue'
import first from 'lodash/first'
import last from 'lodash/last'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core.ts'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'
import { useDeepBoxNodesInfoStore } from '@/stores/deepbox/nodes/info.ts'
import { useOrganization } from '@/composables/use-organization.ts'
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService.ts'
import { DeepBoxError } from '@/models'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

/*
 *                                   !!! PLEASE READ !!!
 *
 * This `middleware` is very important! It's used to catch routes which are not defined in `vue-router`.
 * Also supports the "redirect" of old URLs ( which we used in the past ) and because of dependency
 * of 3rd system's ( like AbacusERP or DeepCloud Onboarding ) we need to still redirect the OLR urls to the new ones.
 * Pls be carefully when change/refactor something here
 *
 * */

// eslint-disable-next-line complexity
export default async function ({
  next,
  to,
}: {
  next: NavigationGuardNext
  to: RouteLocationNormalized
}) {
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
  const deepBoxCoreStore = useDeepBoxCoreStore()
  const deepBoxNodesInfoStore = useDeepBoxNodesInfoStore()
  const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore()

  const { selectOrg } = useOrganization()

  const { isAuthenticated, login } = useKeycloak()

  const { path, fullPath, query } = to
  Sentry.captureMessage('Not found route navigation: ', { extra: { path } })
  const pathArray = to.path.slice(1).split('/')
  const firstItem = first(pathArray)
  const lastItem = last(pathArray)

  // redirect to new Onboarding
  if (/auth/.test(fullPath)) {
    // the new onboarding uses the same URLs as the DeepBox URLs

    // the default case is the removal of the '/auth'
    let url = fullPath.split('/auth')[1]
    url = url.slice(1) // remove the `/`

    // special case of the root page from new onboarding
    if (path === '/auth/register') {
      if (!Object.keys(query).includes('onboarding')) {
        // redirect to {onboarding}/register
        url = fullPath.split('/auth/register')[1]
      }
    }

    const onboardingRedirectUrl = `${
      import.meta.env.VITE_DEEP_ONBOARDING_BASE_URL
    }${url}`
    // Examples:
    // http://localhost:3000/auth/register?lang=de
    // http://localhost:3000/auth/register
    // http://localhost:3000/auth/register?onboarding=deepbox
    // http://localhost:3000/auth/register?onboarding=deepsign
    // http://localhost:3000/auth/register?onboarding=deepv
    // http://localhost:3000/auth/register/verify?email=a@b.ch&verify_type=email&onboarding=deepbox
    // http://localhost:3000/auth/register/new-user?key=qxqTZduw3Tl0AihN5Ix9u_ro75wgW4U9C91fOTurRWk
    // http://localhost:3000/auth/account
    // http://localhost:3000/auth/account/private
    // http://localhost:3000/auth/account/organization
    // http://localhost:3000/auth/account/verify

    // Log to sentry in order to get some feedback about users navigating to old URLs
    Sentry.captureMessage('DeepBox Onboarding route used: ', {
      extra: {
        onboardingNavigatedUrl: fullPath,
        onboardingRedirectUrl,
      },
    })

    // In order to redirect to the new onboarding just replace the domain and do the removal of '/auth'
    window.open(onboardingRedirectUrl, '_self')
    return
  }

  const isPreview = path.includes('preview')
  const isBoxDetails = /queue|files|trash/.test(path)
  let nodeId = ''
  let boxId = ''
  let typeId = ''
  let section = ''

  if (isAuthenticated.value) {
    await deepAdminUsersMeStore.fetchUserOrganizations()
    const userOrganizations = deepAdminUsersMeStore.organizations
    // Check if is preview or box details pages
    if (isPreview) {
      if (lastItem) {
        nodeId = lastItem
      }

      // Fetch node info in order to have the deepbox id and be able to fetch the company id
      try {
        await deepBoxNodesInfoStore.fetchNodeInfo(nodeId)
        if (deepBoxNodesInfoStore.nodeInfo?.deepBoxNodeId) {
          await deepBoxCoreStore.fetchType(
            deepBoxNodesInfoStore.nodeInfo?.deepBoxNodeId,
          )
        }
        // select organization
        if (deepBoxCoreStore.type?.companyId) {
          selectOrg(deepBoxCoreStore.type?.companyId, userOrganizations)
        }

        next({
          name: 'node-preview',
          params: { node: nodeId },
        })
        return
      } catch {
        DeepErrorHandlerService.error(
          new DeepBoxError('error.403.file_not_found_or_no_access', {
            statusCode: 403,
          }),
        )
      }
    } else if (isBoxDetails) {
      if (!lastItem || !firstItem) return
      if (/queue|files|trash/.test(lastItem)) {
        section = lastItem
        typeId = firstItem
        boxId = pathArray[pathArray.length - 2]
      } else {
        section = pathArray[pathArray.length - 2]
        nodeId = lastItem
        typeId = firstItem
        boxId = pathArray[pathArray.length - 3]
      }
      try {
        await deepBoxBoxInfoStore.fetchBoxInfo(boxId)
        if (deepBoxBoxInfoStore.boxInfo?.deepBoxNodeId) {
          typeId = deepBoxBoxInfoStore.boxInfo?.deepBoxNodeId
        }
        const companyId =
          deepBoxBoxInfoStore.boxInfo?.accessGrantedBy[0]?.companyId ||
          deepBoxBoxInfoStore.boxInfo?.company?.companyId
        if (companyId) {
          selectOrg(companyId, userOrganizations)
        }

        // Find the correct route to redirect
        if (nodeId) {
          switch (section) {
            case 'files':
              next({
                name: 'organization-types-type-boxes-box-files-node',
                params: {
                  organization: companyId,
                  type: typeId,
                  box: boxId,
                  node: nodeId,
                },
              })
              break
            case 'trash':
              next({
                name: 'organization-types-type-boxes-box-trash-node',
                params: {
                  organization: companyId,
                  type: typeId,
                  box: boxId,
                  node: nodeId,
                },
              })
              break

            // no default
          }
        } else {
          switch (section) {
            case 'queue':
              next({
                name: 'organization-types-type-boxes-box-inbox',
                params: {
                  organization: companyId,
                  type: typeId,
                  box: boxId,
                },
              })
              break
            case 'files':
              next({
                name: 'organization-types-type-boxes-box-files',
                params: {
                  organization: companyId,
                  type: typeId,
                  box: boxId,
                },
              })
              break
            case 'trash':
              next({
                name: 'organization-types-type-boxes-box-trash',
                params: {
                  organization: companyId,
                  type: typeId,
                  box: boxId,
                },
              })
              break

            // no default
          }
        }
      } catch {
        DeepErrorHandlerService.error({
          statusCode: 403,
          title: 'error.403.box_not_found_or_no_access',
          deepBoxError: true,
        })
        next()
        return
      }
    }
  } else {
    if (isPreview || isBoxDetails) {
      const hostOrigin = window.location.origin
      const redirectUri = `${hostOrigin}${fullPath}`
      login({ redirectUri })
      return
    } else {
      DeepErrorHandlerService.error({
        statusCode: 404,
        title: 'error.page_not_found',
        deepBoxError: true,
      })
      next()
      return
    }
  }

  next()
}
