import LayoutDefault from '@/layouts/LayoutDefault.vue'

export default [
  {
    path: '/watches',
    component: LayoutDefault,
    name: 'watches',
    meta: {},
    children: [
      {
        path: '/watches/stop/:id',
        component: () => import('@/pages/watch/WatchStop.vue'),
        name: 'watches-stop-id',
        meta: {
          auth: false,
        },
      },
    ],
  },
]
