<template>
  <CardExpandable v-if="items.length > 0" :loading="isLoading">
    <template #title>
      <div class="expandable-title text-medium-emphasis">
        {{ t('deeppay.payments_history.card_expandable.title') }}
      </div>
      <v-spacer />
    </template>
    <template #content>
      <DeepPayPaymentsHistoryList
        :box-id="boxId"
        :items="items"
        :origin-organization-id="originOrganizationId"
        :type-id="typeId"
      />
    </template>
  </CardExpandable>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import type { PaymentHistory } from '@/api/types/deepo/payments-history.ts'
import DeepPayPaymentsHistoryList from './DeepPayPaymentsHistoryList.vue'
import CardExpandable from '@/components/cards/CardExpandable.vue'
import { constants } from '@/constants/constants'
import { deepOPaymentsHistoryAPIService } from '@/api/deepo/payments-history.ts'
import { useI18n } from 'vue-i18n'
import { toast } from 'vue-sonner'

const props = defineProps({
  originOrganizationId: {
    type: String,
    required: true,
  },
  typeId: {
    type: String,
    required: true,
  },
  boxId: {
    type: String,
    required: true,
  },
  nodeId: {
    type: String,
    required: true,
  },
  mimeType: {
    type: String,
    required: true,
  },
})

const items = ref<PaymentHistory[]>([])
const { t } = useI18n()

const isLoading = ref(false)

async function fetchPaymentsHistory() {
  isLoading.value = true
  try {
    const { data } = await deepOPaymentsHistoryAPIService.get(props.nodeId, {
      companyId: props.originOrganizationId,
      deepBoxNodeId: props.typeId,
      boxNodeId: props.boxId,
    })
    items.value = data.payments
    sortSource(items.value, 'createdAt', 'desc')
  } catch {
    toast.error(t('error.error_occurred'))
  } finally {
    isLoading.value = false
  }
}

watch(
  () => props.nodeId,
  () => {
    items.value = []
    if (
      [
        constants.MIME_TYPE_PDF,
        constants.MIME_TYPE_DEEPPAY_PAYMENT_ORDER,
      ].includes(props.mimeType)
    ) {
      fetchPaymentsHistory()
    }
  },
  {
    immediate: true,
  },
)

function sortSource(
  source: PaymentHistory[],
  sortField: string,
  sortOrder: 'asc' | 'desc',
) {
  source.sort((a, b) => {
    if (sortOrder === 'asc') {
      return a[sortField]?.localeCompare(b[sortField])
    } else {
      return -a[sortField]?.localeCompare(b[sortField])
    }
  })
}
</script>

<style scoped lang="scss">
.expandable-title {
  font-size: 13px;
}
</style>
