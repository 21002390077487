import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type {
  Download,
  DownloadAdd,
  DownloadsStatusQueryParams,
} from '@/api/types/deepbox/download'

export default class DeepBoxDownloadsAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  post(data: DownloadAdd): Promise<HttpClientResponse<Download>> {
    return this.httpClient.post<Download>(`downloads`, data)
  }

  getStatus(
    downloadId: string,
    params?: DownloadsStatusQueryParams,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<Download>> {
    return this.httpClient.get<Download>(
      `downloads/${downloadId}/status`,
      params,
      options,
    )
  }
}

export const deepBoxDownloadsAPI = new DeepBoxDownloadsAPIService(deepBoxApi)
