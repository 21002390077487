<template>
  <DeepDialog
    :closable="false"
    max-width="700"
    :model-value="show"
    :title="t('overlays.overused_subscription.title')"
  >
    <template #icon>
      <v-icon class="mr-3" color="warning">fas fa-triangle-exclamation</v-icon>
    </template>
    <template #content>
      <v-alert
        class="ma-0"
        density="compact"
        :text="getText"
        :title="t('overlays.overused_subscription.subtitle')"
        variant="tonal"
      >
      </v-alert>
      <div class="text-caption mt-4">
        {{ t('overlays.overused_subscription.learn_more') }}
        <a
          :href="linkKnowledgeCenterSubscriptions"
          rel="noopener noreferrer"
          target="_blank"
        >
          support.deepbox.swiss
        </a>
      </div>
    </template>

    <template #actions>
      <VBtnSecondary
        v-if="!isProdEnv()"
        :block="smAndDown"
        color="error"
        @click="ignore"
      >
        {{ t('buttons.ignore_dev_int_only') }}
      </VBtnSecondary>
      <template v-if="isOwner">
        <v-spacer v-if="!smAndDown" />
        <VBtnTertiary :block="smAndDown" @click="manageOrganization">
          {{ t('overlays.overused_subscription.manage_organization_btn') }}
        </VBtnTertiary>
        <VBtnPrimary :block="smAndDown" @click="manageSubscription">
          {{ t('overlays.overused_subscription.manage_subscription_btn') }}
        </VBtnPrimary>
      </template>
    </template>
  </DeepDialog>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import helpers from '@/utils/helpers.ts'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import { useDeepAdminUsersCompanyAccountingStore } from '@/stores/deepadmin/users/users-company-accounting'
import { useKeycloak } from '@/composables/auth/use-keycloak'
import { isProdEnv } from '@/utils/helpers/env.ts'
import { DeepDialog } from '@deepcloud/deep-ui-lib'
import { useLocalStorage } from '@vueuse/core'
import { useKnowledgeCenter } from '@/composables/use-knowledge-center.ts'
import { useDisplay } from 'vuetify'

const { t } = useI18n()
const { user } = useKeycloak()
const { smAndDown } = useDisplay()

const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
const deepAdminUsersCompanyAccountingStore =
  useDeepAdminUsersCompanyAccountingStore()

const ignored = useLocalStorage(
  `ignore_overused_deepbox_overlay_${user.value?.sub}`,
  {},
)

const isOrgIgnored = computed(() => {
  if (!deepAdminUsersMeStore.selectedOrganizationId) return false
  return Object.keys(ignored.value).includes(
    deepAdminUsersMeStore.selectedOrganizationId,
  )
})

const show = computed(() => {
  if (!isProdEnv()) {
    return (
      deepAdminUsersCompanyAccountingStore.companyAccounting
        .has_overused_box_users && !isOrgIgnored.value
    )
  }
  return (
    deepAdminUsersCompanyAccountingStore.companyAccounting
      .has_overused_box_users || false
  )
})

const currentOrganization = computed(() => {
  return helpers.currentOrganization(
    deepAdminUsersMeStore.selectedOrganization,
    deepAdminUsersMeStore.organizations,
  )
})
const isOwner = computed(() =>
  helpers.isOwnedOrganization(currentOrganization.value),
)

const getText = computed(() => {
  return isOwner.value
    ? t('overlays.overused_subscription.text_owner')
    : t('overlays.overused_subscription.text_member')
})

const { linkSubscriptions: linkKnowledgeCenterSubscriptions } =
  useKnowledgeCenter()

const ignore = () => {
  ignored.value = {
    ...ignored.value,
    [`${deepAdminUsersMeStore.selectedOrganizationId}`]: true,
  }
}

const manageSubscription = () => {
  window.open(
    `${import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL}organizations/${
      deepAdminUsersMeStore.selectedOrganizationId
    }/subscription`,
    '_self',
  )
}

const manageOrganization = () => {
  window.open(
    `${import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL}organizations/${
      deepAdminUsersMeStore.selectedOrganizationId
    }`,
    '_self',
  )
}
</script>
<style lang="scss" scoped>
.card-title {
  word-break: normal !important;
}
</style>
