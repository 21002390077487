<template>
  <v-menu
    v-bind="$attrs"
    ref="menuRef"
    :close-on-content-click="false"
    :location="location"
    :max-width="props.maxWidth"
    :min-width="props.minWidth"
  >
    <!--
      Dynamically inherit slots from parent
      & make all slots from component reusable from parent
    -->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <template v-if="props.isSubMenu" #activator="{ props: ActivatorProps }">
      <v-list-item
        v-bind="ActivatorProps"
        :data-test-id="dataTestId"
        :density="props.density"
        slim
        :title="props[props.titleAccessor]"
      >
        <template v-if="props[props.iconAccessor]" #prepend>
          <v-icon class="mr-2" :color="props.color">
            {{ props[props.iconAccessor] }}
          </v-icon>
        </template>
        <template #append>
          <v-icon class="pa-0 ma-0" size="small">far fa-chevron-right</v-icon>
        </template>
      </v-list-item>
    </template>
    <v-list
      :density="props.density"
      :max-width="props.maxWidth"
      :min-width="props.minWidth"
    >
      <template
        v-for="(item, index) in props.items"
        :key="`core-item-${index}`"
      >
        <v-divider v-if="item.divider" :key="index" :class="item.space" />
        <v-spacer v-else-if="item.spacer" :class="item.space" />
        <CoreMenuNested
          v-else-if="item.children"
          :color="item.color"
          :density="props.density"
          :icon="item.icon"
          :is-sub-menu="true"
          :items="item.children"
          :location="xs ? 'bottom' : 'left'"
          :max-width="props.maxWidth"
          :open-on-focus="false"
          :open-on-hover="canHoverMediaQuery"
          v-bind="$attrs"
          submenu
          :title="item.title"
          @click:item="emitClickEvent"
        />
        <v-list-item
          v-else
          :data-test-id="item.dataTestId"
          :density="props.density"
          v-bind="item"
          slim
          :title="item[titleAccessor]"
          @click="emitClickEvent(item)"
        >
          <template v-if="item.icon" #prepend>
            <v-icon class="mr-2" :color="item[props.iconColorAccessor]">
              {{ item[props.iconAccessor] }}
            </v-icon>
          </template>
          <template v-if="item.action" #append>
            <v-icon
              class="pa-0 ma-0"
              :color="item.action[props.iconColorAccessor]"
            >
              {{ item.action[props.iconAccessor] }}
            </v-icon>
          </template>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { ref } from 'vue'
import type { VMenu } from 'vuetify/components'
import type { MenuNestedItem } from '@/types/menu-item'
import { useHover } from '@/composables/use-hover.ts'
import { useDisplay } from 'vuetify'

const props = defineProps({
  items: {
    type: Array as PropType<MenuNestedItem[]>,
    required: true,
    default: () => [],
  },
  titleAccessor: {
    type: String,
    default: 'title',
  },
  title: {
    type: String,
    default: undefined,
  },
  color: {
    type: String,
    default: undefined,
  },
  icon: {
    type: String,
    default: undefined,
  },
  iconAccessor: {
    type: String,
    default: 'icon',
  },
  iconColorAccessor: {
    type: String,
    default: 'color',
  },
  density: {
    type: String,
    default: 'default',
  },
  isSubMenu: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: [String, Number] as PropType<string | number>,
    default: 200,
  },
  maxWidth: {
    type: [String, Number] as PropType<string | number>,
    default: 300,
  },
  dataTestId: {
    type: String,
    default: undefined,
  },
  location: {
    type: String,
    default: 'bottom',
  },
})

const emit = defineEmits(['click:item'])

const { xs } = useDisplay()

const menuRef = ref<typeof VMenu | null>(null)

const { canHoverMediaQuery } = useHover()

function emitClickEvent(item: MenuNestedItem) {
  emit('click:item', item)
}

const close = () => {
  //  TODO(VUE-3-MIGRATION): V-Menu fix the progr. close
  // menuRef.value?.save();
}

defineExpose({ close })
</script>
