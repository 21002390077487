import { useDeepBoxUploadStore } from '@/stores/deepbox/deepboxes/upload'
import { constants } from '@/constants'
import { useRoute, useRouter } from 'vue-router'
import type { Node } from '@/api/types/deepbox/node'
import helpers, { type UploadEntriesOutput } from '@/utils/helpers.ts'
import { clone } from '@/utils/helpers/clone.ts'
import type { PathSegment } from '@/api/types/deepbox/path.ts'

export function useUploader() {
  const deepBoxUploadStore = useDeepBoxUploadStore()

  const route = useRoute()
  const router = useRouter()

  async function uploadEntries(
    entries: UploadEntriesOutput[],
    targetNode: Node | PathSegment,
  ) {
    console.log('uploadEntries - targetNode', targetNode)
    let targetRoute
    let isInbox
    if (targetNode) {
      isInbox = targetNode.mimeType === constants.MIME_TYPE_INBOX
      const targetSection = helpers.getUploadSectionFromMimeType(
        targetNode?.mimeType,
      )

      const isNestedRoute = targetSection === 'fileNodes'

      let params = {
        organization: route.params.organization,
        type: route.params.type,
        box: route.params.box,
      }

      if (isNestedRoute) {
        params = {
          ...params,
          node: targetNode.nodeId,
        }
      }
      const resolvedRoute = router.resolve({
        name: helpers.getRouteNameBySectionName(targetSection),
        params,
      })
      if (resolvedRoute) {
        targetRoute = {
          name: resolvedRoute.name,
          params: clone(resolvedRoute.params),
          query: clone(resolvedRoute.query),
        }
      }
    } else {
      isInbox = route.name === constants.ROUTE_QUEUE
      targetRoute = {
        name: route.name,
        params: clone(route.params),
        query: clone(route.query),
      }
    }

    console.log('uploadEntries', entries, targetNode)

    entries.forEach((entry) => {
      let payload = {
        route: targetRoute,
        targetNode: targetNode as Node,
      }

      if (entry.hasFolders && !isInbox) {
        payload = {
          ...payload,
          folders: entry.folders,
        }
      }

      if (entry.files.length > 0) {
        payload = {
          ...payload,
          files: entry.files,
        }
      }

      deepBoxUploadStore.setNodesToUpload(payload)
    })
  }

  return {
    uploadEntries,
  }
}
