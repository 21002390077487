import type { BoxEntry } from '@/api/types/deepbox/box'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core'
import { useDeepBoxOverviewStore } from '@/stores/deepbox/overview'
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes.ts'

export function useBoxHelper() {
  const deepBoxCoreStore = useDeepBoxCoreStore()
  const deepBoxOverviewStore = useDeepBoxOverviewStore()
  const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore()

  function selectBox(box: BoxEntry) {
    if (box.boxNodeId !== deepBoxCoreStore.selectedBox?.boxNodeId) {
      deepBoxDeepBoxesBoxesNodesStore.$reset()
      deepBoxCoreStore.selectedBox = box
    }
  }

  function isSharedBox(
    box: BoxEntry,
    sharedBoxes: BoxEntry[],
    currentOrgId: string,
  ) {
    return !!sharedBoxes.find(
      (shared) =>
        shared.boxNodeId === box.boxNodeId &&
        shared.company.companyId !== currentOrgId,
    )
  }

  function isShared(box: BoxEntry, currentOrgId: string) {
    return isSharedBox(box, deepBoxOverviewStore.sharedBoxes, currentOrgId)
  }

  return {
    selectBox,
    isShared,
  }
}
