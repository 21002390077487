import { ref } from 'vue'

export function useDeepaChatOptions(params: { model: string; lang: string }) {
  const currentLang = ref(params.lang)
  const currentModel = ref(params.model)

  function onDeepAChatOptionClick(e) {
    if (e.groupId === 'lang') {
      currentLang.value = e.value
    }

    if (e.groupId === 'model') {
      currentModel.value = e.value
    }
  }

  return {
    currentLang,
    currentModel,
    onDeepAChatOptionClick,
  }
}
