<template>
  <Tree
    v-model="model"
    :busy="busyId"
    class="v-treeview"
    :data="deepBoxDeepBoxesBoxesFilesTreeStore.filesTree"
    draggable
    item-id="nodeId"
    @close="handleClose"
    @open="handleOpen"
  >
    <template #default="{ dataNode, node }">
      <CoreNavigationDrawerListItem
        :id="dataNode.nodeId"
        class="py-0 my-0"
        color="primary"
        :data-test-id="`tree-folder-${dataNode.displayName}`"
        draggable="true"
        exact
        nav
        :ripple="false"
        :style="`padding-inline-start: calc(16px * ${
          node.data.level + 1
        }) !important;`"
        :title="dataNode.displayName"
        :to="{
          name: 'organization-types-type-boxes-box-files-node',
          params: {
            organization: organizationId,
            type: typeId,
            box: boxId,
            node: dataNode.nodeId,
          },
        }"
        variant="text"
        @dragend="emit('item:dragend', { event: $event, item: dataNode })"
        @dragenter.prevent="
          emit('item:dragenter', { event: $event, item: dataNode })
        "
        @dragleave="emit('item:dragleave', { event: $event, item: dataNode })"
        @dragover="emit('item:dragover', { event: $event, item: dataNode })"
        @dragstart="emit('item:dragstart', { event: $event, item: dataNode })"
        @drop="emit('item:drop', { event: $event, item: dataNode })"
      >
        <template #icon-prepend>
          <v-btn
            class="mr-2"
            density="compact"
            icon
            :loading="busyId === dataNode.nodeId"
            size="small"
            slim
            variant="text"
            @click.prevent="
              node.guards.isOpened
                ? node.actions.handleClose(node.data)
                : node.actions.handleOpen(node.data)
            "
          >
            <template #loader>
              <v-icon class="fa-spin" size="x-small">far fa-loader</v-icon>
            </template>
            <v-icon size="x-small">
              {{
                node.guards.isOpened
                  ? 'far fa-angle-down'
                  : 'far fa-angle-right'
              }}
            </v-icon>
          </v-btn>
        </template>
        <template #icon>
          <v-icon
            v-if="dataNode.type === 'folder'"
            class="mx-1"
            :color="isTreeViewNodeActive(dataNode) ? 'primary' : undefined"
            size="small"
            :style="{
              opacity: isTreeViewNodeActive(dataNode) ? 0.9 : undefined,
            }"
          >
            {{ node.guards.isOpened ? 'far fa-folder-open' : 'far fa-folder' }}
          </v-icon>
          <img
            v-else
            alt="node-icon"
            class="mx-1"
            :src="`/svg/files/${getNodeIcon(dataNode)}-20-px.svg`"
          />
        </template>
      </CoreNavigationDrawerListItem>
    </template>
  </Tree>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import '@deepfront/tree/dist/style.css'

// components
import CoreNavigationDrawerListItem from '@/components/core/CoreNavigationDrawerListItem.vue'

// composables
import { Tree } from '@deepfront/tree'
import { useRoute } from 'vue-router'

// stores
import { useDeepBoxDeepBoxesBoxesFilesTreeStore } from '@/stores/deepbox/deepboxes/boxes/files-tree.ts'

// types & constants
import type { FileTree } from '@/types/file-tree.ts'

// utilities
import { getNodeIcon } from '@/utils/helpers/nodes.ts'

const props = defineProps({
  organizationId: {
    type: String,
    required: true,
  },
  typeId: {
    type: String,
    required: true,
  },
  boxId: {
    type: String,
    required: true,
  },
})

const emit = defineEmits([
  'item:drop',
  'item:dragover',
  'item:dragleave',
  'item:dragenter',
  'item:dragstart',
  'item:dragend',
])

const model = ref()
const busyId = ref()

async function handleOpen(value: string, node: FileTree, next: () => void) {
  busyId.value = node.nodeId

  await deepBoxDeepBoxesBoxesFilesTreeStore.fetchFilesTreeChildren({
    typeId: props.typeId,
    boxId: props.boxId,
    nodeId: node.nodeId,
    parent: node,
  })

  next()
  busyId.value = undefined
}

function handleClose(value: string, node: FileTree, next: () => void) {
  next()
}

const deepBoxDeepBoxesBoxesFilesTreeStore =
  useDeepBoxDeepBoxesBoxesFilesTreeStore()

const route = useRoute()

function isTreeViewNodeActive(item: FileTree) {
  return item.nodeId === route?.params?.node
}
</script>

<style scoped lang="scss">
.v-treeview {
  --indent-padding: 6px;
  --prepend-width: 16px;

  //:deep(.v-list-item__overlay) {
  //  background-color: transparent;
  //}

  :deep(.TreeNodeName) {
    &:hover {
      color: rgb(var(--v-theme-primary));
    }
  }

  :deep(.TreeNode) {
    ul,
    li {
      list-style-type: none;
    }
  }
}
</style>
