import type { App } from 'vue'
import { DeepMsgBoxPlugin } from '@deepcloud/deep-ui-lib'
import i18n from '@/plugins/i18n.ts'

export default {
  install: (app: App) => {
    console.log('installing deep-ui plugin')

    app.use(DeepMsgBoxPlugin, {
      msgBox: {
        i18n: i18n.global,
        closable: true,
        btnActions: {
          cancel: {
            name: 'cancel',
            titleI18nKey: 'buttons.cancel',
            color: 'primary',
            variant: 'outlined',
          },
          confirm: {
            name: 'confirm',
            titleI18nKey: 'buttons.confirm',
            color: 'primary',
            variant: 'flat',
          },
        },
        dialogProps: {
          persistent: true,
          width: 450,
        },
      },
    })
    console.log('installing deep-ui: DeepMsgBoxPlugin plugin')
  },
}
