import { useErrorStore } from '@/stores/error/error'
import type { IDeepBoxError } from '@/api/types/error'

class DeepErrorHandlerService {
  static error(error: Error | IDeepBoxError) {
    const errorStore = useErrorStore()
    errorStore.create(error)
  }

  static clear() {
    const errorStore = useErrorStore()
    errorStore.$reset()
  }
}

export default DeepErrorHandlerService
