import axios from 'axios'
import { setInterceptorHeaderAuthorization } from '@/api/interceptors/utils'
import { AxiosHttpClient } from '@/api/http-client'

// create axios
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_DEEPSIGN_API_BASE_URL, // api base_url
})

axiosInstance.interceptors.request.use(async (config) => {
  const headers = {}

  await setInterceptorHeaderAuthorization(headers)

  Object.assign(config.headers, headers)
  return {
    ...config,
  }
})

export default new AxiosHttpClient(axiosInstance)
