<template>
  <DeepDialog
    v-model="model"
    card-text-fluid
    :closable="closable"
    height="90vh"
    max-width="80%"
    show-fullscreen
    title="DeepFlow"
    @close="onCancel"
  >
    <template #content>
      <v-container v-if="model" class="pa-0 ma-0 fill-height" fluid>
        <app-loader v-if="isLoading" />
        <iframe
          v-show="!isLoading"
          allow="clipboard-read; clipboard-write"
          class="iframe"
          height="100%"
          name="deepFlowIframe"
          :src="iframeSrc"
          title="deep-flow-runtime"
          width="100%"
          @load="onIFrameLoad"
        ></iframe>
      </v-container>
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { onUnmounted, ref } from 'vue'
import { DeepDialog } from '@deepcloud/deep-ui-lib'
import { useKeycloak } from '@/composables/auth/use-keycloak'
import { useI18n } from 'vue-i18n'

defineProps({
  iframeSrc: {
    type: String,
    default: '',
  },
  closable: {
    type: Boolean,
    default: false,
  },
})

const model = defineModel({ type: Boolean, default: false })

const { token, tokenType, updateToken, addListener, removeListener } =
  useKeycloak()
const { locale } = useI18n()

const isLoading = ref(false)

window.addEventListener('message', receiveMessage)

onUnmounted(() => {
  window.removeEventListener('message', receiveMessage)
  removeListener('onAuthRefreshSuccess', postMessageToIframe)
})

async function receiveMessage(event: MessageEvent) {
  if (event.data === 'ready') {
    await updateToken(-1)
    postMessageToIframe()

    addListener('onAuthRefreshSuccess', postMessageToIframe)
  }
  if (event.data === 'cancel') {
    close()
  }
}

function postMessageToIframe() {
  const iframe = window.frames['deepFlowIframe']
  const data = {
    token: token.value,
    tokenType: tokenType.value,
    userLang: locale.value,
  }
  iframe.postMessage(JSON.stringify(data), '*')
}

function onIFrameLoad() {
  isLoading.value = false

  window.onmessage = function (event: MessageEvent) {
    if (event.data === 'cancel') {
      close()
    }
  }
}

function close() {
  model.value = false
}

function onCancel() {
  close()
}
</script>
<style lang="scss" scoped>
.iframe {
  border: 0;
}
</style>
