import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { DeepBoxOverview } from '@/api/types/deepbox/deep-box'

export default class DeepBoxOverviewDeepBoxesAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  get(
    deepBoxNodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<DeepBoxOverview>> {
    return this.httpClient.get<DeepBoxOverview>(
      `overview/deepBoxes/${deepBoxNodeId}`,
      params,
      options,
    )
  }
}

export const deepBoxOverviewDeepBoxesAPI =
  new DeepBoxOverviewDeepBoxesAPIService(deepBoxApi)
