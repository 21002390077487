import { deepAdminApi } from '@/api/interceptors'
import type { HttpClient, HttpClientResponse } from '@/api/http-client'
import type { Support } from '@/api/types/deepadmin/users/users-support'

export default class DeepAdminUsersSupportAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  post(payload: {
    files: File[]
    type: string | Blob
    text: string | Blob
    contact: string | Blob
  }): Promise<HttpClientResponse<Support>> {
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    const formData = new FormData()
    if (payload.files) {
      payload.files.forEach((file) => formData.append('files', file))
    }
    formData.append('type', payload.type)
    formData.append('text', payload.text)
    formData.append('contact', payload.contact)
    return this.httpClient.post<Support>(`users/support`, formData, config)
  }
}

export const deepAdminUsersSupportAPI = new DeepAdminUsersSupportAPIService(
  deepAdminApi,
)
