import { deepBoxApi } from '@/api/interceptors'
import type {
  HttpClient,
  HttpClientGetOptions,
  HttpClientResponse,
} from '@/api/http-client'
import type { DeepBox } from '@/api/types/deepbox/deep-box'

export default class DeepBoxDeepBoxesAPIService {
  private httpClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient
  }

  getById(
    deepBoxNodeId: string,
    params?: Record<string, unknown>,
    options?: HttpClientGetOptions,
  ): Promise<HttpClientResponse<DeepBox>> {
    return this.httpClient.get<DeepBox>(
      `deepBoxes/${deepBoxNodeId}`,
      params,
      options,
    )
  }
}

export const deepBoxDeepBoxesAPI = new DeepBoxDeepBoxesAPIService(deepBoxApi)
