<template>
  <v-layout class="app">
    <StoreAppBar class="app--toolbar" />
    <v-main>
      <div class="page-wrapper">
        <router-view />
      </div>
    </v-main>
  </v-layout>
</template>

<script lang="ts" setup>
import StoreAppBar from '@/components/store/StoreAppBar.vue'
</script>

<style lang="scss" scoped>
.page-wrapper {
  height: 100%;
}
</style>
